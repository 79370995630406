import React, { useCallback, useEffect, useMemo } from 'react';
import {
  C_FatLineBorderMaterial,
  C_FatLineSelectedBorderMaterial,
  C_WallMaterial,
  C_WallSelectedMaterial,
} from '@/shared/materials';
import {
  convertFlatVector3ToVectors,
  createGeometryFromVectorList,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import {
  FlatVector3,
  NodeType,
  SelectedNodeSource,
  UserBuildingStorey,
  UserBuildingBlock,
  UserBuildingWall,
  WindowPlacementData,
  CanvasActionsModes,
} from '@/models';
import { useSelectedNodes } from '@/shared/hooks/useSelectedNodes';
import { useHoveredNode } from '@/shared/hooks/useHoveredNode';
import * as THREE from 'three';
import { ThreeEvent } from '@react-three/fiber';
import { LineGeometry } from 'three-stdlib';
import {
  getCenterFromFlatVectorsArray,
  getXYZ,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import {
  getIsNodeIsolated,
  getIsNodeSelected,
  getIsSingleNodeSelected,
  isSingleNodeIsolated,
  setExtrudeNode,
} from '@/store/slices/canvasBuildingSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { GenericChildSurface } from '@/models/building-nodes.model';
import ExtrudeDotHandler, {
  ExtrudeHandlerData,
} from '@/routes/dashboard/projects/project/UserBuilding/components/ExtrudeTool/ExtrudeDotHandler';
import {
  getIsCutModeEnabled,
  getIsFacadeDesignerModeEnabled,
  setMode,
} from '@/store/slices/canvasModesSlice';
import ViewerWindow from './ViewerWindow';
import { getMultiplyRate } from '@/store/slices/canvasMapSlice';
import { getDraggedWindowFromLibrary } from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { POLYGON_OFFSET_FACTOR_LEVELS } from '@/shared/constants';

interface WallProps extends GenericChildSurface {
  data: UserBuildingWall;
  blockData: UserBuildingBlock;
  blockGUID: string;
  storeyData: UserBuildingStorey;
  isParentSelected: boolean;
  isParentHovered: boolean;
  isParentLocked: boolean;
  isParentEdited?: boolean;
  isRoof?: boolean;
  isLowestFloor?: boolean;
  isolateMode: boolean;
  floorShapedCoordinates: FlatVector3[];
  storeyCenter: THREE.Vector3;

  storeyGUID: string;
  buildingGUID: string;
}

const Wall: React.FC<WallProps> = ({
  data,
  isParentSelected,
  isParentHovered,
  isParentLocked,
  isRoof,
  isLowestFloor,
  isolateMode,
  storeyGUID,
  blockGUID,
  floorShapedCoordinates,
  storeyCenter,
}) => {
  const dispatch = useAppDispatch();
  const { selectNode } = useSelectedNodes();

  const multiplyRate = useAppSelector(getMultiplyRate);
  const isWallSelected = useAppSelector(getIsNodeSelected(data.guid));
  const isStoreySelected = useAppSelector(getIsNodeSelected(storeyGUID));
  const isWallIsolated = useAppSelector(getIsNodeIsolated(data.guid));
  const isSingleNodeSelected = useAppSelector(getIsSingleNodeSelected);
  const isOnlyWallIsolated = useAppSelector(isSingleNodeIsolated);
  const isCutModeEnabled = useAppSelector(getIsCutModeEnabled);
  const isDesignerMode = useAppSelector(getIsFacadeDesignerModeEnabled);
  const draggedWindowFromLibrary = useAppSelector(getDraggedWindowFromLibrary);
  const isWindowCardDragging = !!draggedWindowFromLibrary;

  const isExtrudeAvailable =
    isWallSelected &&
    isSingleNodeSelected &&
    !data.userData?.isLocked &&
    !isParentLocked &&
    (!isWallIsolated || isolateMode) &&
    !isOnlyWallIsolated &&
    !isDesignerMode;

  const { setHoveredNode, resetHoveredNode, isNodeHovered } = useHoveredNode({
    nodeGUID: data.guid,
    isLocked: data.userData?.isLocked || isParentLocked,
    source: SelectedNodeSource.Viewer,
    nodeType: NodeType.Wall,
  });

  const { isNodeHovered: isBuildingHovered } = useHoveredNode({
    nodeGUID: blockGUID,
  });

  const { isNodeHovered: isStoreyHovered } = useHoveredNode({
    nodeGUID: storeyGUID,
  });

  const {
    setHoveredNode: setHoveredStorey,
    resetHoveredNode: resetHoveredStorey,
  } = useHoveredNode({
    nodeGUID: storeyGUID,
    isLocked: isParentLocked,
    source: SelectedNodeSource.Viewer,
  });

  const extrudeHandlerData = useMemo((): ExtrudeHandlerData => {
    const center = getCenterFromFlatVectorsArray(data.points);

    return {
      defaultCenter: [center.x, center.y, center.z],
      node: { type: NodeType.Wall, guid: data.guid },
      extendAnchor: [storeyCenter.x, storeyCenter.y, storeyCenter.z],
      wallCoordinates: data.points,
    };
  }, [data, storeyCenter]);

  const handleSelectWallForExtrude = (node: ExtrudeHandlerData) => {
    dispatch(setExtrudeNode(node));
  };

  const isSelected = isParentSelected || isWallSelected;

  const { points } = data;
  const wallCoordinates = useMemo(
    () => convertFlatVector3ToVectors(points),
    [data]
  );

  const wallGeometry = useMemo(
    () => createGeometryFromVectorList(wallCoordinates, 'vertical'),
    [wallCoordinates]
  );

  const wallMaterial = useMemo(() => {
    return isSelected && !isDesignerMode
      ? C_WallSelectedMaterial.clone()
      : C_WallMaterial.clone();
  }, [isSelected, isNodeHovered, isCutModeEnabled, isDesignerMode]);

  const edgeCoordinates = useMemo(() => {
    const coordinates: THREE.Vector3[][] = [];
    for (let i = 0; i < wallCoordinates.length - 1; i++) {
      coordinates.push([wallCoordinates[i], wallCoordinates[i + 1]]);
    }
    coordinates.push([
      wallCoordinates[wallCoordinates.length - 1],
      wallCoordinates[0],
    ]);
    return coordinates;
  }, [wallCoordinates]);

  const verticalBorderIndexes = edgeCoordinates.reduce(
    (acc: number[], curr, index) => {
      curr[0].z === curr[1].z && acc.push(index);
      return acc;
    },
    []
  );
  const topBorderIndex = edgeCoordinates.findIndex((bc) => {
    const max = Math.max(...wallCoordinates.map((c) => c.y));
    return bc[0].y === max && bc[1].y === max;
  });
  const bottomBorderIndex = edgeCoordinates.findIndex((bc) => {
    const min = Math.min(...wallCoordinates.map((c) => c.y));
    return bc[0].y === min && bc[1].y === min;
  });

  const handleSelectStorey = (event: ThreeEvent<PointerEvent>) => {
    selectNode({
      event,
      type: NodeType.Storey,
      guid: storeyGUID,
      isLocked: isParentLocked,
      source: SelectedNodeSource.Viewer,
      isSelected: isParentSelected,
    });
  };

  const handleSelectWall = (event: ThreeEvent<PointerEvent>) => {
    selectNode({
      event,
      type: NodeType.Wall,
      guid: data.guid,
      isLocked: data.userData?.isLocked || isParentLocked,
      isSelected: isWallSelected,
    });
  };

  const generateWallBorders = useCallback(() => {
    return edgeCoordinates.map((v, i) => {
      const geometry = new LineGeometry();
      geometry.setPositions([...getXYZ(v[0]), ...getXYZ(v[1])]);

      let material = C_FatLineBorderMaterial.clone();

      //color all borders if wall is selected
      if (
        isWallSelected ||
        isNodeHovered ||
        isStoreyHovered ||
        isStoreySelected
      ) {
        material = C_FatLineSelectedBorderMaterial.clone();
        material.polygonOffsetFactor = POLYGON_OFFSET_FACTOR_LEVELS.HIGH;
      }

      const handlers = {
        onPointerDown: handleSelectStorey,
        onPointerEnter: setHoveredStorey,
        onPointerLeave: resetHoveredStorey,
      };

      const line = new Line2(geometry, material);
      line.geometry.instanceCount = 2;

      line.computeLineDistances();

      return (
        <primitive
          object={line}
          key={i}
          {...(verticalBorderIndexes.includes(i) ? handlers : {})}
        />
      );
    });
  }, [
    edgeCoordinates,
    verticalBorderIndexes,
    isParentSelected,
    isParentHovered,
    isWallSelected,
    isNodeHovered,
    topBorderIndex,
    isRoof,
    isLowestFloor,
    bottomBorderIndex,
    isBuildingHovered,
  ]);

  const isDisplay = !isolateMode || (isolateMode && isWallIsolated);

  const iterateOverWindows = (windows?: WindowPlacementData[]) =>
    windows?.map((placement, i) => (
      <ViewerWindow
        scale={multiplyRate}
        placementData={placement}
        wallData={data.points}
        key={`placed_window-${data.guid}-${i}`}
      />
    ));

  const handleMouseUp = (event: MouseEvent) => {
    if (isNodeHovered) {
      selectNode({
        event: event,
        type: NodeType.Wall,
        guid: data.guid,
        isLocked: isParentLocked,
        source: SelectedNodeSource.Viewer,
        isSelected: isParentSelected,
      });
      dispatch(setMode(CanvasActionsModes.facadeDesigner));
    }
  };

  useEffect(() => {
    if (isWindowCardDragging) {
      window.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isWindowCardDragging, isNodeHovered]);

  if (!isDisplay) return null;

  return (
    <>
      {
        <mesh
          geometry={wallGeometry}
          material={wallMaterial}
          onPointerEnter={setHoveredNode}
          onPointerLeave={resetHoveredNode}
          onPointerDown={handleSelectWall}
          userData={{
            ...data.userData,
            nodeType: NodeType.Wall,
            originalBuildingBlock: {
              guid: blockGUID,
            },
          }}
        >
          {generateWallBorders()}
        </mesh>
      }
      {iterateOverWindows(data.windowPlacements)}
      {isExtrudeAvailable && (
        <ExtrudeDotHandler
          extrudeHandlerData={extrudeHandlerData}
          shapeCoordinates={floorShapedCoordinates}
          clickAction={() => handleSelectWallForExtrude(extrudeHandlerData)}
        />
      )}
    </>
  );
};

export default Wall;
