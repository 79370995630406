import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { SavedWindow } from '@/components/WindowCreator/models/konva-model';
import { FacadeDesignerModes } from '@/models/shared.model';

export interface FacadeDesignerWindow {
  wallGUID: string;
  offset: number;
}

interface FacadeDesignerState {
  modifiedWalls: string[]; // GUID's
  selectedWindowFromLibrary?: SavedWindow;
  selectedWindowPosition?: { x: number; y: number };
  facadeDesignerMode: FacadeDesignerModes;
  selectedPlacedWindow: FacadeDesignerWindow[];
  hoveredPlacedWindow: FacadeDesignerWindow | null;
  draggedWindowFromLibrary?: SavedWindow;
}

const initialState: FacadeDesignerState = {
  modifiedWalls: [],
  selectedPlacedWindow: [],
  hoveredPlacedWindow: null,
  facadeDesignerMode: FacadeDesignerModes.WindowPlacement,
};

export const facadeDesignerSlice = createSlice({
  name: 'facadeDesigner',
  initialState,
  reducers: {
    setSelectedWindowFromLibrary: (
      state,
      action: PayloadAction<SavedWindow>
    ) => {
      state.selectedWindowFromLibrary = action.payload;
    },
    resetFacadeDesignerSlice: () => initialState,
    resetSelectedWindowFromLibrary: (state) => {
      state.selectedWindowFromLibrary = undefined;
    },
    addSelectedPlacedWindow: (
      state,
      action: PayloadAction<FacadeDesignerWindow>
    ) => {
      state.selectedPlacedWindow.push(action.payload);
    },
    setSelectedPlacedWindow: (
      state,
      action: PayloadAction<FacadeDesignerWindow[]>
    ) => {
      state.selectedPlacedWindow = action.payload;
    },
    setModifiedWalls: (state, action: PayloadAction<string[]>) => {
      state.modifiedWalls = action.payload;
    },
    setHoveredPlacedWindow: (
      state,
      action: PayloadAction<FacadeDesignerWindow | null>
    ) => {
      state.hoveredPlacedWindow = action.payload;
    },
    setFacadeDesignerMode: (
      state,
      action: PayloadAction<FacadeDesignerModes>
    ) => {
      state.facadeDesignerMode = action.payload;
    },
    setDraggedWindowFromLibrary: (
      state,
      action: PayloadAction<SavedWindow | undefined>
    ) => {
      state.draggedWindowFromLibrary = action.payload;
    },
  },
});

export const {
  setSelectedWindowFromLibrary,
  resetSelectedWindowFromLibrary,
  resetFacadeDesignerSlice,
  setSelectedPlacedWindow,
  setHoveredPlacedWindow,
  addSelectedPlacedWindow,
  setModifiedWalls,
  setFacadeDesignerMode,
  setDraggedWindowFromLibrary,
} = facadeDesignerSlice.actions;

export const getSelectedWindowFromLibrary = (state: RootState) =>
  state.windowsReducer.facadeDesigner.selectedWindowFromLibrary;

export const isWindowFromLibrarySelected = (id: number) => (state: RootState) =>
  state.windowsReducer.facadeDesigner.selectedWindowFromLibrary?.id === id;

export const isSomeWindowFromLibrarySelected = (state: RootState) =>
  !!state.windowsReducer.facadeDesigner.selectedWindowFromLibrary;

export const getSelectedPlacedWindows = (state: RootState) =>
  state.windowsReducer.facadeDesigner.selectedPlacedWindow;

export const getHoveredPlacedWindow = (state: RootState) =>
  state.windowsReducer.facadeDesigner.hoveredPlacedWindow;

export const getModifiedWalls = (state: RootState) =>
  state.windowsReducer.facadeDesigner.modifiedWalls;

export const getDraggedWindowFromLibrary = (state: RootState) =>
  state.windowsReducer.facadeDesigner.draggedWindowFromLibrary;

export const getFacadeDesignerMode = (state: RootState) =>
  state.windowsReducer.facadeDesigner.facadeDesignerMode;
