import React, { useMemo } from 'react';
import {
  FlatVector2Axis,
  MeasurementElementType,
  MeasurementUpdateData,
  SavedWindow,
} from '@/components/WindowCreator/models';
import { MeasurementsModel } from '@/models/measurements.model';
import { Measurement } from '@/shared/components/MeasurementLine';
import { useAppSelector } from '@/store/hooks';
import { getFacadeDesignerMode } from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { FacadeDesignerModes } from '@/models/shared.model';
import { getWindowWidth } from '@/shared/helpers';
import { WindowPlacementData } from '@/models';

interface WindowPlacementMeasurementProps extends MeasurementsModel {
  windowsData: SavedWindow[];
  placedWindows: WindowPlacementData[];
  wallHeight: number;
  wallWidth: number;
}

const WindowPlacementMeasurements = ({
  windowsData,
  placedWindows,
  units,
  scale,
  wallHeight,
  wallWidth,
  onMeasurementSubmit,
}: WindowPlacementMeasurementProps) => {
  const facadeDesignerMode = useAppSelector(getFacadeDesignerMode);
  const getPreviousPlacedWindow = (
    window: WindowPlacementData
  ): WindowPlacementData | null =>
    placedWindows.reduce((prev: WindowPlacementData | null, cur) => {
      const diff = window.offsetFromLeftEdge - cur.offsetFromLeftEdge;
      if (diff <= 0) return prev;

      return window.offsetFromLeftEdge - cur.offsetFromLeftEdge <
        window.offsetFromLeftEdge - (prev?.offsetFromLeftEdge || 0)
        ? cur
        : prev;
    }, null);

  const getWindowDataById = (id: number): SavedWindow | undefined =>
    windowsData.find((window) => window.id === id);

  const generatedPoints: FlatVector2Axis[] = useMemo(() => {
    const points: FlatVector2Axis[] = placedWindows.map((window) => {
      const previousPlacedWindow = getPreviousPlacedWindow(window);
      const previousWindowData = previousPlacedWindow
        ? getWindowDataById(previousPlacedWindow.windowId)
        : null;
      return [
        [
          (previousPlacedWindow ? previousPlacedWindow.offsetFromLeftEdge : 0) +
            (previousWindowData ? getWindowWidth(previousWindowData) : 0),
          wallHeight / 2,
        ],
        [window.offsetFromLeftEdge, wallHeight / 2],
      ];
    });

    // Generate additional measurement, displayed after last window (i.e. for X windows we should have X + 1 measurements
    if (points.length > 0) {
      const lastWindow = [...placedWindows].sort(
        (a, b) => b.offsetFromLeftEdge - a.offsetFromLeftEdge
      )[0];
      const windowData = getWindowDataById(lastWindow.windowId)!;
      points.push([
        [
          lastWindow.offsetFromLeftEdge + getWindowWidth(windowData),
          wallHeight / 2,
        ],
        [wallWidth, wallHeight / 2],
      ]);
    }
    return points;
  }, [placedWindows, windowsData]);

  const handleMeasurementSubmit = (data: MeasurementUpdateData) => {
    onMeasurementSubmit(data);
  };

  return (
    <>
      {generatedPoints.map((points, idx) => (
        <Measurement
          key={`measurement_windows_${idx}`}
          points={points}
          scale={scale}
          units={units}
          level={0}
          disabled={facadeDesignerMode === FacadeDesignerModes.WindowPlacement}
          onMeasurementSubmit={handleMeasurementSubmit}
          type={MeasurementElementType.WindowDistance}
        />
      ))}
    </>
  );
};

export default WindowPlacementMeasurements;
