import React, { useMemo } from 'react';
import Surface from '@/routes/dashboard/projects/project/UserBuilding/components/Surface';
import Wall from '@/routes/dashboard/projects/project/UserBuilding/components/Wall';
import {
  EditModes,
  FlatVector3,
  NodeType,
  SelectedNode,
  UserBuildingBlock,
  UserBuildingStorey,
} from '@/models';
import * as THREE from 'three';
import { getCenterFromFlatVectorsArray } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import ExtrudeDotHandler, {
  ExtrudeHandlerData,
} from '@/routes/dashboard/projects/project/UserBuilding/components/ExtrudeTool/ExtrudeDotHandler';
import { flatten } from 'lodash';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getEditedNode,
  getIsNodeEdited,
  getIsNodeSelected,
  getSelectedNodes,
  setExtrudeNode,
} from '@/store/slices/canvasBuildingSlice';
import { useHoveredNode } from '@/shared/hooks/useHoveredNode';
import { useIsolationHandlers } from '@/shared/hooks/useIsolationHandlers';
import { GenericChildSurface } from '@/models/building-nodes.model';
import { getEditMode } from '@/store/slices/canvasModesSlice';
import { getAllWallsAtOneSideAtBlock } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import SplitWall from '@/routes/dashboard/projects/project/UserBuilding/components/SplitTool/SplitWall';

interface StoreyProps extends GenericChildSurface {
  block: UserBuildingBlock;
  storey: UserBuildingStorey;
  isLastFloor?: boolean;
  isolateMode: boolean;

  buildingGUID: string;
  blockGUID: string;
}

const Storey: React.FC<StoreyProps> = ({
  block,
  blockGUID,
  storey,
  isParentSelected,
  isParentHovered,
  isParentLocked,
  isolateMode,
  isParentEdited,
  isLastFloor,
  buildingGUID,
}) => {
  const dispatch = useAppDispatch();
  const { isNodeHovered } = useHoveredNode({ nodeGUID: storey.guid });

  const { isNodeIsolated } = useIsolationHandlers();

  const isStoreySelected = useAppSelector(getIsNodeSelected(storey.guid));
  const editedNode = useAppSelector(getEditedNode);
  const editMode = useAppSelector(getEditMode);
  const isStoreyIsolated: boolean = isNodeIsolated(storey.guid);

  const isStoreyEdited = useAppSelector(getIsNodeEdited(storey.guid));

  const selectedNodes = useAppSelector(getSelectedNodes);

  const isExtrudeEnabled =
    isStoreySelected && !storey.userData?.isLocked && !isParentLocked;

  const isSelected = isStoreySelected || isParentSelected;

  const isSomeWallHidden = storey.walls?.some(
    (wall) => wall.userData?.isHidden
  );

  const handleSelectWallForExtrude = (node: ExtrudeHandlerData) => {
    dispatch(setExtrudeNode(node));
  };

  const storeyFacedCenters = useMemo((): ExtrudeHandlerData[] | null => {
    const selectedNode = Object.values(selectedNodes)[0];
    if (!selectedNode || selectedNode.type !== NodeType.Storey) return null;

    const walls =
      !isolateMode || (isolateMode && isStoreyIsolated) ? storey.walls : null;

    if (!walls?.length) return null;

    const storeyWalls: {
      point: THREE.Vector3;
      node: SelectedNode;
      coordinates: FlatVector3[];
    }[] = [];

    walls.forEach((wall) => {
      const center = getCenterFromFlatVectorsArray(wall.points);
      storeyWalls.push({
        point: center,
        node: { type: NodeType.Wall, guid: wall.guid },
        coordinates: wall.points,
      });
    });
    const storeyCenter = getCenterFromFlatVectorsArray(
      flatten(storey?.walls.map((wall) => wall.points))
    );
    return storeyWalls.map((storeyWall) => ({
      defaultCenter: [
        storeyWall.point.x,
        storeyWall.point.y,
        storeyWall.point.z,
      ],
      node: storeyWall.node,
      extendAnchor: [storeyCenter.x, storeyCenter.y, storeyCenter.z],
      wallCoordinates: storeyWall.coordinates,
    }));
  }, [storey, selectedNodes, isolateMode, isStoreyIsolated]);

  const showSurfaces =
    (!isolateMode || (isolateMode && isStoreyIsolated)) &&
    !storey.userData?.isHidden &&
    !isSomeWallHidden;

  const getAllWallsAtOneSide = (wallGuid: string) => {
    return getAllWallsAtOneSideAtBlock(block, wallGuid);
  };

  const shouldDisplaySplitWall = (wallGuid: string, storeyGuid: string) => {
    if (editMode !== EditModes.Split) return false;
    if (editedNode?.type === NodeType.Wall)
      return editedNode?.guid === wallGuid;
    if (editedNode?.type === NodeType.Storey)
      return editedNode?.guid === storeyGuid;
    if (editedNode?.type === NodeType.Block)
      return (
        editedNode?.guid === blockGUID && getAllWallsAtOneSide(wallGuid)?.length
      );
  };

  return (
    <group
      key={storey.guid}
      userData={{
        ...storey.userData,
        nodeType: NodeType.Storey,
      }}
    >
      {showSurfaces && (
        <>
          <Surface
            data={storey.floor}
            key={storey.floor.guid}
            isParentSelected={isSelected}
            buildingGUID={buildingGUID}
            blockGUID={blockGUID}
          />
          <Surface
            data={storey.ceiling}
            key={storey.ceiling.guid}
            isParentSelected={isSelected}
            isParentHovered={isParentHovered}
            isParentLocked={isParentLocked}
            isRoof={isLastFloor}
            buildingGUID={buildingGUID}
            blockGUID={blockGUID}
          />
        </>
      )}
      {storey.walls
        .filter((wall) => !wall.userData?.isHidden)
        .map((wall) => {
          if (shouldDisplaySplitWall(wall.guid, storey.guid)) {
            return (
              <SplitWall
                data={wall}
                key={wall.guid}
                isolateMode={isolateMode}
                isParentEdited={isStoreyEdited || isParentEdited}
                isParentLocked={!!isParentLocked}
                isRoof={isLastFloor}
                isLowestFloor={storey.storeyNumber === 1}
                storeyGUID={storey.guid}
                buildingGUID={buildingGUID}
                storeyData={storey}
                blockData={block}
                blockGUID={blockGUID}
              />
            );
          }

          return (
            <Wall
              data={wall}
              key={wall.guid}
              isolateMode={isolateMode}
              isParentSelected={isParentSelected || isStoreySelected}
              isParentHovered={isParentHovered || isNodeHovered}
              isParentEdited={isParentEdited || isStoreyEdited}
              isParentLocked={isParentLocked || !!storey.userData?.isLocked}
              isRoof={isLastFloor}
              isLowestFloor={storey.storeyNumber === 1}
              storeyGUID={storey.guid}
              buildingGUID={buildingGUID}
              storeyData={storey}
              blockData={block}
              blockGUID={blockGUID}
              floorShapedCoordinates={storey.floor.points}
              storeyCenter={getCenterFromFlatVectorsArray(
                flatten(storey?.walls.map((wall) => wall.points))
              )}
            />
          );
        })}
      {isExtrudeEnabled &&
        storeyFacedCenters?.map((handlerPoint, i) => {
          return (
            <ExtrudeDotHandler
              extrudeHandlerData={handlerPoint}
              key={i}
              shapeCoordinates={storey.floor.points}
              clickAction={() => handleSelectWallForExtrude(handlerPoint)}
            />
          );
        })}
    </group>
  );
};

export default Storey;
