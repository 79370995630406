import React, { useEffect, useRef, useState } from 'react';

import { Resizable } from 're-resizable';
import { subscribe, unsubscribe } from '@/core/events';
import { MAKE_SCREENSHOT } from '@/core/event-names';
import { makeScreenshotDelay } from '@/shared/helpers/camera';
import { sleep } from '@/shared/helpers/sleep';
import BrowserPanel from './BrowserPanel';
import LibraryPanel from './LibraryPanel/LibraryPanel';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getCanvasMode } from '@/store/slices/canvasModesSlice';
import { CanvasActionsModes } from '@/models';
import { setLeftPanelWidth } from '@/store/slices/sharedSlice';
import { LEFT_PANEL_DEFAULT_WIDTH } from '@/shared/constants';

enum LeftPanelViews {
  Browser,
  WindowLibrary,
}
const LeftPanel = () => {
  const dispatch = useAppDispatch();
  const mode = useAppSelector(getCanvasMode);
  const isPlacementMode = mode === CanvasActionsModes.facadeDesigner;

  const [view, setView] = useState(LeftPanelViews.Browser);
  const [previousView, setPreviousView] = useState(LeftPanelViews.Browser);
  const [isAnimating, setIsAnimating] = useState(false);

  const leftPanelRef = useRef<HTMLDivElement>(null!);

  useEffect(() => {
    subscribe(MAKE_SCREENSHOT, toggleAnimation);
    return () => {
      unsubscribe(MAKE_SCREENSHOT, toggleAnimation);
    };
  }, []);

  const toggleAnimation = async () => {
    setIsAnimating(true);
    await sleep(makeScreenshotDelay);
    setIsAnimating(false);
  };

  useEffect(() => {
    if (isPlacementMode) {
      setPreviousView(view);
      setView(LeftPanelViews.WindowLibrary);
    } else {
      setView(previousView);
    }
  }, [isPlacementMode]);

  return (
    <div
      ref={leftPanelRef}
      className={`absolute left-0 top-0 h-full z-20 flex flex-col overflow-x-hidden overflow-y-hidden max-h-full border border-light-gray-20 border-solid border-t-0 duration-500 ${isAnimating ? 'opacity-0' : 'opacity-100'}`}
    >
      <Resizable
        enable={{
          top: false,
          right: true,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        minWidth={LEFT_PANEL_DEFAULT_WIDTH}
        maxWidth={500}
        defaultSize={{ width: LEFT_PANEL_DEFAULT_WIDTH, height: '100%' }}
        className="!h-full"
        onResize={(_, __, elementRef) => {
          dispatch(setLeftPanelWidth(elementRef.offsetWidth - 1));
        }}
      >
        <div className="flex bg-white font-medium pl-1 text-xs min-h-8 items-center">
          <div
            className={`h-full flex items-center justify-center ml-2 ${isPlacementMode ? 'cursor-not-allowed !opacity-20' : ''} ${view === LeftPanelViews.WindowLibrary && !isPlacementMode ? 'font-normal opacity-40 cursor-pointer' : 'cursor-default'}`}
            onClick={() => !isPlacementMode && setView(LeftPanelViews.Browser)}
          >
            Browser
          </div>
          <div
            className={`h-full flex items-center justify-center ml-2 ${view === LeftPanelViews.Browser ? 'font-normal opacity-40 cursor-pointer' : 'cursor-default'}`}
            onClick={() =>
              !isPlacementMode && setView(LeftPanelViews.WindowLibrary)
            }
          >
            Library
          </div>
        </div>
        <div
          className={
            'text-xs font-light border border-r-0 border-solid border-light-gray-20 flex-1 h-[calc(100%_-_2rem)] !bg-white [&_.ant-collapse]:!border-none [&_.ant-collapse-header-text]:w-[90%] overflow-y-auto'
          }
        >
          {view === LeftPanelViews.Browser && <BrowserPanel />}
          {view === LeftPanelViews.WindowLibrary && <LibraryPanel />}
        </div>
      </Resizable>
    </div>
  );
};

export default LeftPanel;
