import React, { useMemo } from 'react';
import EditToolbarButton from './EditToolbarButton';
import { CutIcon, DeleteIcon, SplitIcon } from './icons';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import { useIsolationHandlers } from '@/shared/hooks/useIsolationHandlers';
import {
  BlockSearchResults,
  BuildingSearchResults,
  StoreySearchResults,
  useFindNodeData,
  WallSearchResults,
} from '@/shared/hooks/useFindNodeData';
import {
  constructionElementTypes,
  getStatusByNodeOrParents,
} from '@/shared/helpers/construction-user-data';
import {
  CanvasActionsModes,
  EditModes,
  FlatVector3,
  NodeType,
  SelectedNode,
} from '@/models';
import { setEditMode, setMode } from '@/store/slices/canvasModesSlice';
import { useAppDispatch } from '@/store/hooks';
import {
  setEditedNode,
  setHoveredNode,
} from '@/store/slices/canvasBuildingSlice';
import WindowPlacementIcon from '@/shared/elements/EditToolbar/icons/WindowPlacementIcon';
import { resetSelectedWindowFromLibrary } from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { Html } from '@react-three/drei';
import { calculateCenterCoordinatesWithOffset } from '@/shared/helpers/canvas-verifiers';

const EditToolbar = ({
  selectedNode,
}: {
  projectId: number;
  selectedNode: SelectedNode;
}) => {
  const Divider = (
    <div className={'bg-light-gray-10 h-5 mx-0.5 my-auto w-0.5'} />
  );

  const dispatch = useAppDispatch();
  const userBuildingUtils = useUpdateUserBuildingData();
  const { isIsolateModeEnabled, isNodeIsolated } = useIsolationHandlers();

  const { getNodeData } = useFindNodeData();

  const isIsolated = isNodeIsolated(selectedNode.guid);

  const isNodeHiddenByIsolation = isIsolateModeEnabled ? !isIsolated : false;

  const isAllowedElementSelected = constructionElementTypes.includes(
    selectedNode.type
  );

  const nodeData = getNodeData({
    guid: selectedNode.guid,
    nodeType: selectedNode.type,
  });

  if (!nodeData) return null;

  const coordinates = useMemo(() => {
    const nodePointsMap: { [key in NodeType]?: FlatVector3[] } = {
      [NodeType.Building]: (nodeData as BuildingSearchResults)?.roofPoints,
      [NodeType.Block]: (nodeData as BlockSearchResults)?.roofPoints,
      [NodeType.Storey]: (nodeData as StoreySearchResults)?.ceiling?.points,
      [NodeType.Wall]: (nodeData as WallSearchResults)?.points,
    };

    return calculateCenterCoordinatesWithOffset(
      nodePointsMap[selectedNode.type]
    );
  }, [nodeData]);

  const isNodeHidden = getStatusByNodeOrParents(nodeData, 'isHidden');
  const isNodeLocked = getStatusByNodeOrParents(nodeData, 'isLocked');

  const isSplitModeAvailable =
    (selectedNode.type === NodeType.Building &&
      nodeData.childNodes.filter((node) => node.type === NodeType.Block)
        .length === 1) ||
    (constructionElementTypes.includes(selectedNode.type) &&
      selectedNode.type !== NodeType.Building);

  const isCutModeAvailable =
    (selectedNode.type === NodeType.Building &&
      nodeData.childNodes.filter((node) => node.type === NodeType.Block)
        .length === 1) ||
    selectedNode.type === NodeType.Block;

  const handleDelete = () => {
    userBuildingUtils.deleteSelectedConstructions([nodeData]);
  };

  const handleClickCut = () => {
    const node =
      selectedNode.type === NodeType.Building
        ? nodeData.childNodes[0]
        : selectedNode;
    dispatch(setEditedNode(node));
    dispatch(setHoveredNode(node.guid));
    dispatch(setEditMode(EditModes.Cut));
  };

  const handleClickSplit = () => {
    const node =
      selectedNode.type === NodeType.Building
        ? nodeData.childNodes[0]
        : selectedNode;
    dispatch(setEditedNode(node));
    dispatch(setHoveredNode(node.guid));
    dispatch(setEditMode(EditModes.Split));
  };

  const handleClickWindowPlacement = () => {
    dispatch(setMode(CanvasActionsModes.facadeDesigner));
    dispatch(resetSelectedWindowFromLibrary());
  };

  const handlePointerDown = (event: React.PointerEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const getActions = () => {
    const displayedActions = [];

    if (isCutModeAvailable)
      displayedActions.push({
        element: (
          <EditToolbarButton
            id="edit-toolbar__cut-button"
            icon={<CutIcon />}
            onPointerDown={handleClickCut}
          />
        ),
      });

    if (isSplitModeAvailable)
      displayedActions.push({
        element: (
          <EditToolbarButton
            id="edit-toolbar__splite-button"
            icon={<SplitIcon />}
            onPointerDown={handleClickSplit}
          />
        ),
      });

    if (
      displayedActions.length > 0 &&
      [NodeType.Block, NodeType.Panel, NodeType.Building].includes(
        selectedNode.type
      )
    ) {
      displayedActions.push({ element: Divider });
    }
    if (
      [NodeType.Block, NodeType.Panel, NodeType.Building].includes(
        selectedNode.type
      )
    ) {
      displayedActions.push({
        element: (
          <EditToolbarButton
            id="edit-toolbar__delete-button"
            icon={<DeleteIcon />}
            onPointerDown={handleDelete}
          />
        ),
      });
    }

    if (selectedNode.type === NodeType.Wall) {
      displayedActions.push({
        element: (
          <EditToolbarButton
            id="edit-toolbar__window-placement-button"
            icon={<WindowPlacementIcon />}
            onPointerDown={handleClickWindowPlacement}
          />
        ),
      });
    }
    return displayedActions;
  };

  const showToolbar =
    isAllowedElementSelected &&
    !isNodeHidden &&
    !isNodeLocked &&
    !isNodeHiddenByIsolation;

  return showToolbar ? (
    <Html occlude={false} position={coordinates}>
      <div className="relative" onPointerDown={handlePointerDown}>
        <div
          className={`translate-x-[-50%] rounded-md shadow-toolbar flex bg-white p-0.5 gap-0.5 items-center`}
        >
          {getActions().map((action, i) => (
            <span key={i}>{action.element}</span>
          ))}
        </div>
        <div className="absolute top-[27px] w-0 h-0 border-x-[8px] border-solid border-x-transparent border-t-[8px] border-b-0 border-t-white translate-x-[-50%] mx-auto" />
      </div>
    </Html>
  ) : (
    <></>
  );
};

export default EditToolbar;
