import { useParams } from 'react-router';
import { useAppDispatch } from '@/store/hooks';
import {
  projectsApi,
  useDeleteConstructionMutation,
  useUpdateUserBuildingBlockMutation,
  useUpdateUserBuildingDataMutation,
  useUpdateUserBuildingNodeMutation,
  useUpdateUserBuildingStoreyMutation,
  useUpdateUserBuildingWallMutation,
} from '@/store/apis/projectsApi';
import {
  DrawModes,
  NodeType,
  UserBuildingBlock,
  UserBuildingCoordinatesObject,
  UserBuildingStorey,
  WindowPlacementData,
} from '@/models';
import { removeFromSelectedNodeArray } from '@/store/slices/canvasBuildingSlice';
import { useFindNodeData } from '@/shared/hooks/useFindNodeData';

export const useUpdateUserBuildingData = () => {
  const dispatch = useAppDispatch();
  const { getNodeData } = useFindNodeData();
  const { id } = useParams();
  const [updateUserBuildingNode] = useUpdateUserBuildingNodeMutation();
  const [updateUserBuildingStorey] = useUpdateUserBuildingStoreyMutation();
  const [updateUserBuildingWall] = useUpdateUserBuildingWallMutation();
  const [updateUserBuildingBlock] = useUpdateUserBuildingBlockMutation();
  const [updateUserBuilding] = useUpdateUserBuildingDataMutation();
  const [deleteConstruction] = useDeleteConstructionMutation();

  const updateUserBuildingUserData = (
    buildingGUID: string,
    key: string,
    value: unknown
  ) => {
    let newUserData;
    dispatch(
      projectsApi.util.updateQueryData('fetchProject', id!, (projectData) => {
        if (projectData && projectData.buildings?.length) {
          const buildingIndex = projectData.buildings?.findIndex(
            (building) => building.guid === buildingGUID
          );
          if (buildingIndex === undefined) return;
          newUserData = projectData.buildings![buildingIndex].userData = {
            ...projectData.buildings![buildingIndex]?.userData,
            [key]: value,
          };
          projectData.buildings![buildingIndex].userData = {
            ...projectData.buildings![buildingIndex].userData,
            [key]: value,
          };
        }
      })
    );
    newUserData &&
      updateUserBuilding({
        data: {
          guid: buildingGUID,
          userData: newUserData,
        },
      });
  };

  const updateUserBuildingName = (buildingGUID: string, name: string) => {
    dispatch(
      projectsApi.util.updateQueryData('fetchProject', id!, (projectData) => {
        if (projectData) {
          const buildingIndex = projectData.buildings?.findIndex(
            (building) => building.guid === buildingGUID
          );
          if (buildingIndex === undefined) return;

          projectData!.buildings![buildingIndex]!.name = name;
        }
      })
    );
    updateUserBuilding({
      data: {
        guid: buildingGUID,
        name,
      },
    });
  };

  const deleteSelectedConstructions = (
    selectedConstructions: {
      guid: string;
      nodeType: NodeType;
    }[]
  ) => {
    const isAllConstructionsSameType = selectedConstructions.every(
      (node) => node.nodeType === selectedConstructions[0].nodeType
    );
    if (!isAllConstructionsSameType || selectedConstructions.length === 0)
      return;

    const guids = selectedConstructions.map((c) => c.guid);

    switch (selectedConstructions[0].nodeType) {
      case NodeType.Block:
        deleteBlocks(guids);
        break;
      case NodeType.Building:
        deleteUserBuildings(guids);
        break;
    }
  };

  const deleteBlocks = (guids: string[]) => {
    const blocks = guids.map((guid) =>
      getNodeData({ guid, nodeType: NodeType.Block })
    );

    const buildings = blocks.reduce(
      (acc: { [key: string]: string[] }, curr) => {
        if (!curr) return acc;
        const building = curr.parentNodes.find(
          (parent) => parent.type === NodeType.Building
        )!;
        if (!building) return acc;

        return {
          ...acc,
          [building.guid]: [
            ...(acc[building.guid] ? acc[building.guid] : []),
            curr.guid,
          ],
        };
      },
      {}
    );

    Object.keys(buildings).forEach((buildingGuid) => {
      const buildingData = getNodeData({
        guid: buildingGuid,
        nodeType: NodeType.Building,
      });
      if (!buildingData) return;
      if (
        buildingData.childNodes.filter((node) => node.type === NodeType.Block)
          .length === guids.length
      ) {
        deleteUserBuildings([buildingGuid]);
      } else {
        dispatch(
          projectsApi.util.updateQueryData(
            'fetchProject',
            id!,
            (projectData) => {
              if (projectData) {
                const buildingIdx = projectData.buildings?.findIndex(
                  (b) => b.guid === buildingGuid
                );
                if (buildingIdx === undefined) return;
                projectData.buildings![buildingIdx].blocks =
                  projectData.buildings![buildingIdx].blocks?.filter(
                    (bl) => !buildings[buildingGuid].includes(bl.guid)
                  );
              }
            }
          )
        );

        deleteConstruction({
          data: {
            blockGuids: buildings[buildingGuid].map((blockGuid) => blockGuid),
          },
          projectId: Number(id),
        });
      }
    });

    dispatch(removeFromSelectedNodeArray(guids));
  };

  const deleteUserBuildings = (guids: string[]) => {
    dispatch(
      projectsApi.util.updateQueryData('fetchProject', id!, (projectData) => {
        if (projectData) {
          projectData.buildings = projectData?.buildings?.filter(
            (building) => !guids.includes(building.guid)
          );
        }
      })
    );
    deleteConstruction({
      data: {
        buildingGuids: guids.map((guid) => guid),
      },
      projectId: Number(id),
    });

    dispatch(removeFromSelectedNodeArray(guids));
  };

  const updateWallWindowPlacements = ({
    buildingGUID,
    blockGUID,
    storeyGUID,
    wallGUID,
    windowPlacements,
  }: {
    buildingGUID: string;
    blockGUID: string;
    storeyGUID: string;
    wallGUID: string;
    windowPlacements: WindowPlacementData[];
  }) => {
    dispatch(
      projectsApi.util.updateQueryData('fetchProject', id!, (projectData) => {
        if (projectData && projectData.buildings?.length) {
          const buildingIndex = projectData.buildings?.findIndex(
            (building) => building.guid === buildingGUID
          );
          if (buildingIndex === undefined) return;

          const blockIdx = projectData.buildings![
            buildingIndex
          ].blocks.findIndex((block) => block.guid === blockGUID);

          projectData.buildings![buildingIndex].blocks[blockIdx].storeys =
            projectData.buildings![buildingIndex].blocks[blockIdx].storeys.map(
              (storey) =>
                storey.guid === storeyGUID
                  ? {
                      ...storey,
                      walls: storey.walls.map((wall) =>
                        wall.guid === wallGUID
                          ? {
                              ...wall,
                              windowPlacements,
                            }
                          : wall
                      ),
                    }
                  : storey
            );
        }
      })
    );

    updateUserBuildingWall({
      data: {
        guid: wallGUID,
        windowPlacements,
      },
    });
  };

  const updateWallUserData = ({
    blockGUID,
    storeyGUID,
    buildingGUID,
    wallGUID,
    key,
    value,
  }: {
    buildingGUID: string;
    blockGUID: string;
    storeyGUID: string;
    wallGUID: string;
    key: string;
    value: unknown;
  }) => {
    let newUserData;
    dispatch(
      projectsApi.util.updateQueryData('fetchProject', id!, (projectData) => {
        if (projectData && projectData.buildings?.length) {
          const buildingIndex = projectData.buildings?.findIndex(
            (building) => building.guid === buildingGUID
          );
          if (buildingIndex === undefined) return;

          const blockIdx = projectData.buildings![
            buildingIndex
          ].blocks.findIndex((block) => block.guid === blockGUID);

          projectData.buildings![buildingIndex].blocks[blockIdx].storeys =
            projectData.buildings![buildingIndex].blocks[blockIdx].storeys.map(
              (storey) =>
                storey.guid === storeyGUID
                  ? {
                      ...storey,
                      walls: storey.walls.map((wall) => {
                        if (wall.guid === wallGUID) {
                          newUserData = {
                            ...wall.userData,
                            [key]: value,
                          };
                          return {
                            ...wall,
                            userData: {
                              ...wall.userData,
                              [key]: value,
                            },
                          };
                        }
                        return wall;
                      }),
                    }
                  : storey
            );
        }
      })
    );
    newUserData &&
      updateUserBuildingNode({
        data: { guid: wallGUID, userData: newUserData },
      });
  };

  const updateWallName = ({
    buildingGUID,
    blockGUID,
    storeyGUID,
    wallGUID,
    name,
  }: {
    buildingGUID: string;
    blockGUID: string;
    storeyGUID: string;
    wallGUID: string;
    name: string;
  }) => {
    dispatch(
      projectsApi.util.updateQueryData('fetchProject', id!, (projectData) => {
        if (projectData) {
          const buildingIndex = projectData.buildings?.findIndex(
            (building) => building.guid === buildingGUID
          );
          if (buildingIndex === undefined) return;

          const blockIdx = projectData.buildings![
            buildingIndex
          ].blocks.findIndex((block) => block.guid === blockGUID);

          projectData.buildings![buildingIndex].blocks[blockIdx].storeys =
            projectData.buildings![buildingIndex].blocks[blockIdx].storeys.map(
              (storey) =>
                storey.guid === storeyGUID
                  ? {
                      ...storey,
                      walls: storey.walls.map((wall) =>
                        wall.guid === wallGUID
                          ? {
                              ...wall,
                              name,
                            }
                          : wall
                      ),
                    }
                  : storey
            );
        }
      })
    );
    updateUserBuildingNode({
      data: { guid: wallGUID, name },
    });
  };

  const updateUserBuildingFloorUserData = ({
    buildingGUID,
    blockGUID,
    storeyGUID,
    key,
    value,
  }: {
    buildingGUID: string;
    blockGUID: string;
    storeyGUID: string;
    key: string;
    value: unknown;
  }) => {
    let newUserData;
    dispatch(
      projectsApi.util.updateQueryData('fetchProject', id!, (projectData) => {
        if (projectData && projectData.buildings?.length) {
          const buildingIndex = projectData.buildings?.findIndex(
            (building) => building.guid === buildingGUID
          );
          if (buildingIndex === undefined) return;

          const blockIdx = projectData.buildings![
            buildingIndex
          ].blocks.findIndex((block) => block.guid === blockGUID);

          projectData.buildings![buildingIndex].blocks[blockIdx].storeys =
            projectData.buildings![buildingIndex].blocks[blockIdx].storeys.map(
              (storey) => {
                if (storey.guid === storeyGUID) {
                  newUserData = {
                    ...storey.userData,
                    [key]: value,
                  };

                  return {
                    ...storey,
                    userData: {
                      ...storey.userData,
                      [key]: value,
                    },
                  };
                }
                return storey;
              }
            );
        }
      })
    );
    newUserData &&
      updateUserBuildingStorey({
        data: {
          userData: newUserData,
          guid: storeyGUID,
        },
      });
  };

  const updateUserBuildingBlockUserData = ({
    buildingGUID,
    blockGUID,
    key,
    value,
  }: {
    buildingGUID: string;
    blockGUID: string;
    key: string;
    value: unknown;
  }) => {
    let newUserData;
    dispatch(
      projectsApi.util.updateQueryData('fetchProject', id!, (projectData) => {
        if (projectData && projectData.buildings?.length) {
          const buildingIndex = projectData.buildings?.findIndex(
            (building) => building.guid === buildingGUID
          );
          if (buildingIndex === undefined) return;

          projectData.buildings![buildingIndex].blocks = projectData.buildings![
            buildingIndex
          ].blocks.map((block) => {
            if (block.guid === blockGUID) {
              newUserData = {
                ...block.userData,
                [key]: value,
              };

              return {
                ...block,
                userData: {
                  ...block.userData,
                  [key]: value,
                },
              };
            }
            return block;
          });
        }
      })
    );
    newUserData &&
      updateUserBuildingBlock({
        data: {
          userData: newUserData,
          guid: blockGUID,
        },
      });
  };

  const updateUserBuildingBlockStoreys = ({
    blockGUID,
    updatedBlock,
  }: {
    blockGUID: string;
    updatedBlock: UserBuildingBlock;
  }) => {
    dispatch(
      projectsApi.util.updateQueryData('fetchProject', id!, (projectData) => {
        if (projectData && projectData.buildings?.length) {
          const buildingIndex = projectData.buildings?.findIndex((building) =>
            building.blocks.some((block) => block.guid === blockGUID)
          );
          if (buildingIndex === undefined) return;
          projectData.buildings![buildingIndex].blocks = projectData.buildings![
            buildingIndex
          ].blocks.map((block) =>
            block.guid === blockGUID ? updatedBlock : block
          );
        }
      })
    );
  };

  const renameUserBuildingBlock = ({
    buildingGUID,
    blockGUID,
    name,
  }: {
    buildingGUID: string;
    blockGUID: string;
    name: string;
  }) => {
    dispatch(
      projectsApi.util.updateQueryData('fetchProject', id!, (projectData) => {
        if (projectData) {
          const buildingIndex = projectData.buildings?.findIndex(
            (building) => building.guid === buildingGUID
          );
          if (buildingIndex === undefined) return;

          const blockIdx = projectData.buildings![
            buildingIndex
          ].blocks.findIndex((block) => block.guid === blockGUID);

          projectData.buildings![buildingIndex].blocks[blockIdx].name = name;
        }
      })
    );
    updateUserBuildingBlock({
      data: {
        name,
        guid: blockGUID,
      },
    });
  };

  const updateBlockStoreys = ({
    buildingGUID,
    blockGUID,
    newStoreys,
  }: {
    buildingGUID: string;
    blockGUID: string;
    newStoreys: UserBuildingStorey[];
  }) => {
    dispatch(
      projectsApi.util.updateQueryData('fetchProject', id!, (projectData) => {
        if (projectData) {
          const buildingIndex = projectData.buildings?.findIndex(
            (building) => building.guid === buildingGUID
          );
          if (buildingIndex === undefined) return;

          const blockIdx = projectData.buildings![
            buildingIndex
          ].blocks.findIndex((block) => block.guid === blockGUID);

          projectData.buildings![buildingIndex].blocks[blockIdx].storeys =
            newStoreys;
        }
      })
    );
    newStoreys &&
      updateUserBuildingBlock({
        data: {
          guid: blockGUID,
          storeys: newStoreys,
        },
      });
  };

  const renameUserBuildingFloor = ({
    buildingGUID,
    blockGUID,
    storeyGUID,
    name,
  }: {
    buildingGUID: string;
    storeyGUID: string;
    blockGUID: string;
    name: string;
  }) => {
    dispatch(
      projectsApi.util.updateQueryData('fetchProject', id!, (projectData) => {
        if (projectData) {
          const buildingIndex = projectData.buildings?.findIndex(
            (building) => building.guid === buildingGUID
          );
          if (buildingIndex === undefined) return;

          const blockIdx = projectData.buildings![
            buildingIndex
          ].blocks.findIndex((block) => block.guid === blockGUID);

          projectData.buildings![buildingIndex].blocks[blockIdx].storeys =
            projectData.buildings![buildingIndex].blocks[blockIdx].storeys.map(
              (storey) =>
                storey.guid === storeyGUID
                  ? {
                      ...storey,
                      name,
                    }
                  : storey
            );
        }
      })
    );
    updateUserBuildingStorey({
      data: {
        name,
        guid: storeyGUID,
      },
    });
  };

  const createUserBuilding = (userBuilding: UserBuildingCoordinatesObject) => {
    dispatch(
      projectsApi.util.updateQueryData('fetchProject', id!, (projectData) => {
        if (projectData) {
          projectData.buildings?.push(userBuilding);
        }
      })
    );
  };

  const updateUserBuildingStoreyData = ({
    buildingGuid,
    blockGuid,
    storeyGuid,
    newStorey,
    saveStoreysLocally = false,
    updateBlock = false,
  }: {
    buildingGuid: string;
    blockGuid: string;
    storeyGuid: string;
    newStorey: UserBuildingStorey;
    updateBlock?: boolean;
    saveStoreysLocally?: boolean;
  }) => {
    let newStoreys: UserBuildingStorey[] = [];
    dispatch(
      projectsApi.util.updateQueryData('fetchProject', id!, (projectData) => {
        if (projectData) {
          const buildingIndex = projectData.buildings?.findIndex(
            (building) => building.guid === buildingGuid
          );
          if (buildingIndex === undefined) return;

          const blockIdx = projectData.buildings![
            buildingIndex
          ].blocks.findIndex((block) => block.guid === blockGuid);
          const storeyIdx = projectData.buildings![buildingIndex].blocks[
            blockIdx
          ].storeys.findIndex((storey) => storey.guid === storeyGuid);

          if (storeyIdx === undefined) return;
          projectData.buildings![buildingIndex].blocks[blockIdx].storeys[
            storeyIdx
          ] = newStorey;

          if (updateBlock) {
            newStoreys = JSON.parse(
              JSON.stringify(
                projectData.buildings![buildingIndex].blocks[blockIdx].storeys
              )
            );
          }
        }
      })
    );

    !updateBlock &&
      !saveStoreysLocally &&
      updateUserBuildingStorey({
        data: newStorey,
      });

    updateBlock &&
      newStoreys.length > 0 &&
      updateUserBuildingBlock({
        data: {
          guid: blockGuid,
          storeys: newStoreys,
        },
      });
  };

  const updateUserBuildingDrawMode = ({
    buildingGuid,
    drawMode,
  }: {
    buildingGuid: string;
    drawMode: DrawModes;
  }) => {
    let previousDrawMode;
    dispatch(
      projectsApi.util.updateQueryData('fetchProject', id!, (projectData) => {
        if (projectData) {
          const buildingIndex = projectData.buildings?.findIndex(
            (building) => building.guid === buildingGuid
          );
          if (buildingIndex === undefined || !projectData.buildings) return;

          previousDrawMode = projectData.buildings[buildingIndex].drawMode;
          projectData.buildings[buildingIndex].drawMode = drawMode;
        }
      })
    );

    previousDrawMode !== drawMode &&
      updateUserBuilding({
        data: { guid: buildingGuid, drawMode },
      });
  };

  const cutBuildingBlock = ({
    buildingGUID,
    blockGUID,
    newBlocks,
  }: {
    buildingGUID: string;
    blockGUID: string;
    newBlocks: UserBuildingBlock[];
  }) => {
    let data: Partial<UserBuildingCoordinatesObject> = {};
    dispatch(
      projectsApi.util.updateQueryData('fetchProject', id!, (projectData) => {
        if (projectData) {
          const buildingIndex = projectData.buildings?.findIndex(
            (building) => building.guid === buildingGUID
          );
          if (buildingIndex === undefined) return;

          projectData.buildings![buildingIndex].blocks = projectData.buildings![
            buildingIndex
          ].blocks.filter((block) => block.guid !== blockGUID);

          projectData.buildings![buildingIndex].blocks.push(...newBlocks);

          data = {
            guid: buildingGUID,
            name: projectData.buildings![buildingIndex].name,
            userData: projectData.buildings![buildingIndex].userData,
            drawMode: projectData.buildings![buildingIndex].drawMode,
            centerLineCoordinates: JSON.parse(
              JSON.stringify(
                projectData.buildings![buildingIndex].centerLineCoordinates
              )
            ),
            blocks: projectData.buildings![buildingIndex].blocks,
          };
        }
      })
    );
    data &&
      updateUserBuilding({
        data,
      });
  };

  return {
    updateUserBuildingUserData,
    updateUserBuildingName,
    updateWallUserData,
    updateWallName,
    updateWallWindowPlacements,
    deleteUserBuildings,
    deleteBlocks,
    deleteSelectedConstructions,
    updateUserBuildingFloorUserData,
    renameUserBuildingFloor,
    createUserBuilding,
    renameUserBuildingBlock,
    updateUserBuildingBlockUserData,
    updateUserBuildingBlockStoreys,
    updateBlockStoreys,
    updateUserBuildingStoreyData,
    updateUserBuildingDrawMode,
    updateBuildingBlocks: cutBuildingBlock,
  };
};
