import * as THREE from 'three';
import {
  FlatVector3,
  UserBuildingBlock,
  UserBuildingStorey,
  UserBuildingSurface,
} from '@/models';
import {
  convert3DVectorsTo2D,
  getXYZ,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { Earcut } from 'three/src/extras/Earcut';
import { isEqual } from 'lodash';
import { uuidv7 } from 'uuidv7';

export const FLOOR_HEIGHT = 0.03;
export const DEFAULT_FLOOR_HEIGHT_IN_METERS = 3.048;

export const convertPositionAttributeToVectors = (
  position: THREE.BufferAttribute | THREE.InterleavedBufferAttribute
): FlatVector3[] => {
  const vectors: FlatVector3[] = [];
  for (let i = 0; i < position.count; i++) {
    vectors.push(getXYZ(new THREE.Vector3().fromBufferAttribute(position, i)));
  }
  return vectors;
};

export const convertFlatVector3ToVectors = (
  points: FlatVector3[]
): THREE.Vector3[] => {
  return points.map((point) => new THREE.Vector3(point[0], point[1], point[2]));
};

export const convertFlatVector3ToVector = (
  point: FlatVector3
): THREE.Vector3 => {
  return new THREE.Vector3(point[0], point[1], point[2]);
};

export const convertFlatVector3ToVector2 = (point: FlatVector3) =>
  new THREE.Vector2(point[0], point[2]);

export const createGeometryFromVectorList = (
  points: THREE.Vector3[],
  direction: 'horizontal' | 'vertical'
) => {
  const geometry = new THREE.BufferGeometry().setFromPoints(points);

  const triangulate = Earcut.triangulate(
    convert3DVectorsTo2D(
      points,
      direction === 'horizontal' ? ['x', 'z'] : ['x', 'y']
    )
      .map((point) => [point.x, point.y])
      .flat()
  );

  geometry.setIndex(triangulate);
  geometry.computeBoundingBox();

  return geometry;
};

export const getAllWallsAtOneSideAtBlock = (
  block: UserBuildingBlock,
  wallGuid: string
) => {
  const allWallsAtOneSide = [];

  for (let i = 0; i < block.storeys[0].walls.length; i++) {
    const oneSideWalls = [];
    let wall = block.storeys[0].walls[i];
    oneSideWalls.push(wall);
    for (let j = 1; j < block.storeys.length; j++) {
      const foundedWall = block.storeys[j]?.walls.find((storeyWall) => {
        const amountOfSamePoints = storeyWall?.points?.reduce((acc, curr) => {
          return wall.points.some(
            (point) =>
              point[0].toFixed(3) === curr[0].toFixed(3) &&
              point[1].toFixed(3) === curr[1].toFixed(3) &&
              point[2].toFixed(3) === curr[2].toFixed(3)
          )
            ? acc + 1
            : acc;
        }, 0);
        return amountOfSamePoints === 2;
      });
      if (foundedWall) {
        oneSideWalls.push(foundedWall);
        wall = foundedWall;
      }
    }
    allWallsAtOneSide.push(oneSideWalls);
  }

  const sideWallsOnAllFloors = allWallsAtOneSide.find((sideWalls) =>
    sideWalls.some((wall) => wall.guid === wallGuid)
  );

  return block.storeys.length === sideWallsOnAllFloors?.length
    ? sideWallsOnAllFloors
    : [];
};

export const generateWalls = (
  floorPoints: THREE.Vector3[],
  ceilingPoints: THREE.Vector3[],
  previousWallsData?: UserBuildingSurface[]
): UserBuildingSurface[] => {
  const walls: UserBuildingSurface[] = [];
  for (let i = 0; i < floorPoints.length - 1; i++) {
    const wallPoints = [
      getXYZ(floorPoints[i]),
      getXYZ(floorPoints[i + 1]),
      getXYZ(ceilingPoints[i + 1]),
      getXYZ(ceilingPoints[i]),
    ];
    const existedWallData = previousWallsData?.find((wallData) =>
      isEqual(wallData.points, wallPoints)
    );
    walls.push({
      name: `New Wall ${i}`,
      userData: {},
      ...existedWallData,
      guid: uuidv7(),
      points: wallPoints,
    });
  }

  return walls;
};

export const generateStorey = (
  floorPoints: THREE.Vector3[],
  storeyNumber: number,
  ceilingPoints: THREE.Vector3[],
  previousStoreyData?: UserBuildingStorey
) => {
  const calculatedHeight = ceilingPoints[0].y - floorPoints[0].y;

  const ceiling: UserBuildingSurface = {
    name: `Ceiling ${storeyNumber}`,
    userData: {},
    ...previousStoreyData?.ceiling,
    guid: uuidv7(),
    points: ceilingPoints
      ? ceilingPoints.map((point) => getXYZ(point))
      : floorPoints.map((point) => [
          point.x,
          point.y + calculatedHeight,
          point.z,
        ]),
  };
  const floor: UserBuildingSurface = {
    name: `Floor ${storeyNumber}`,
    userData: {},
    ...previousStoreyData?.floor,
    guid: uuidv7(),
    points: floorPoints.map((point) => getXYZ(point)),
  };

  return {
    name: `Floor ${storeyNumber}`,
    userData: {},
    storeyNumber: storeyNumber,
    ...previousStoreyData,
    guid: uuidv7(),
    floor,
    ceiling,
    walls: generateWalls(
      floorPoints,
      convertFlatVector3ToVectors(ceiling.points),
      previousStoreyData?.walls
    ),
  };
};
