import React from 'react';
import { useAppSelector } from '@/store/hooks';
import { useParams } from 'react-router';
import {
  getExtrudeNode,
  getSelectedNodes,
} from '@/store/slices/canvasBuildingSlice';
import { getCanvasMode } from '@/store/slices/canvasModesSlice';
import { CanvasActionsModes } from '@/models';
import EditToolbar from '@/shared/elements/EditToolbar';

const CanvasInternalElements = () => {
  const { id } = useParams();
  const selectedNodes = Object.values(useAppSelector(getSelectedNodes));
  const extrudedNode = useAppSelector(getExtrudeNode);
  const mode = useAppSelector(getCanvasMode);

  const isDesignerMode = mode === CanvasActionsModes.facadeDesigner;
  const showEditToolbar =
    !extrudedNode && selectedNodes.length === 1 && !isDesignerMode;

  return (
    <>
      {showEditToolbar && (
        <EditToolbar projectId={Number(id!)} selectedNode={selectedNodes[0]} />
      )}
    </>
  );
};

export default CanvasInternalElements;
