import React, { useEffect, useRef, useState } from 'react';
import { Layer, Stage } from 'react-konva';
import { WindowManContour } from '@/components/WindowCreator/elements';
import {
  INITIAL_SCALE,
  STAGE_HEIGHT,
  STAGE_WIDTH,
} from '@/components/WindowCreator/constants';
import Konva from 'konva';
import KonvaEventObject = Konva.KonvaEventObject;
import { WindowCreatorMeasurements } from '@/components/WindowCreator/elements/Measurements';
import {
  MeasurementUpdateData,
  WindowCreatorFormData,
  WindowElementType,
} from '@/components/WindowCreator/models/konva-model';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  clearSelectRelatedData,
  getHasSelectedNode,
  getSelectedWindowNodeType,
} from '@/store/slices/windowsReducer/windowCreatorSlice';
import SelectWrapper from '@/components/WindowCreator/elements/SelectionArea';
import { WindowCreatorModes } from '@/models/shared.model';
import { FormInstance } from 'antd';
import { subscribe, unsubscribe } from '@/core/events';
import { SET_WINDOW_CONTEXT_MENU_POSITION } from '@/core/event-names';
import WindowMenu from '../elements/WindowMenu/WindowMenu';
import { MullionData } from '@/models/window-configurator.model';
import { useKonvaHandlers } from '@/shared/hooks/useKonvaHandlers';
import WindowView from '@/components/WindowView/WindowView';
import { WINDOW_CREATOR_STAGE_ID } from '@/shared/constants';

interface WindowCreatorContainerProps {
  data: WindowCreatorFormData;
  mode: WindowCreatorModes;
  onMeasurementSubmit: (data: MeasurementUpdateData) => void;
  onDeleteMullionSubmit: (data: MullionData) => void;
  onKonvaInit: (stage: Konva.Stage) => void;
  form: FormInstance<WindowCreatorFormData>;
}
export const WindowCreatorContainer = ({
  data,
  onMeasurementSubmit,
  onDeleteMullionSubmit,
  mode,
  form,
  onKonvaInit,
}: WindowCreatorContainerProps) => {
  const dispatch = useAppDispatch();
  const hasSelectedNode = useAppSelector(getHasSelectedNode);
  const selectedNodeType = useAppSelector(getSelectedWindowNodeType);
  const isSelectionMode = mode === WindowCreatorModes.Selection;
  const stageRef = useRef<Konva.Stage>(null!);

  useEffect(() => {
    stageRef.current && onKonvaInit(stageRef.current);
  }, [stageRef.current]);

  Konva.dragButtons = [2];

  const [stageParams, setStageParams] = useState({
    scale: INITIAL_SCALE,
    x: 0,
    y: 0,
  });
  const [menuPosition, setMenuPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const { wheelHandler } = useKonvaHandlers({ maxZoom: 0.66, minZoom: 0.1 });

  const handleWheel = (event: KonvaEventObject<WheelEvent>) => {
    const scaleData = wheelHandler(event);

    scaleData && setStageParams(scaleData);
  };

  const handleClick = (event: KonvaEventObject<MouseEvent>) => {
    if (event.target.nodeType === 'Stage') {
      dispatch(clearSelectRelatedData());
    }
  };

  useEffect(() => {
    subscribe(SET_WINDOW_CONTEXT_MENU_POSITION, (evt) => {
      setMenuPosition(evt.detail);
    });
    return () => {
      unsubscribe(SET_WINDOW_CONTEXT_MENU_POSITION, (evt) => {
        setMenuPosition(evt.detail);
      });
      dispatch(clearSelectRelatedData());
    };
  }, []);

  const showMenu =
    menuPosition &&
    hasSelectedNode &&
    selectedNodeType !== WindowElementType.Mullion;

  return (
    <Stage
      id={WINDOW_CREATOR_STAGE_ID}
      ref={stageRef}
      onContextMenu={(e) => e.evt.preventDefault()}
      width={STAGE_WIDTH}
      height={STAGE_HEIGHT}
      scaleX={stageParams.scale}
      scaleY={stageParams.scale}
      x={stageParams.x}
      y={stageParams.y}
      draggable
      onClick={handleClick}
      onWheel={handleWheel}
      onDragEnd={() => {}}
      onDragMove={() => {}}
    >
      {data.points && (
        <Layer>
          <WindowView
            data={data}
            scale={stageParams.scale}
            units={data.units}
            onDeleteMullionSubmit={onDeleteMullionSubmit}
            view={form.getFieldValue('view')}
            mode={mode}
          />
          <WindowCreatorMeasurements
            onMeasurementSubmit={onMeasurementSubmit}
            data={data}
            units={data.units}
            scale={stageParams.scale}
          />

          <WindowManContour
            outerFramePoints={data.points}
            units={data.units}
            scale={stageParams.scale}
          />

          {isSelectionMode && <SelectWrapper scale={stageParams.scale} />}
          {showMenu && (
            <WindowMenu
              form={form}
              scale={stageParams.scale}
              menuPosition={menuPosition}
              setMenuPosition={setMenuPosition}
              windowData={data}
            />
          )}
        </Layer>
      )}
    </Stage>
  );
};
