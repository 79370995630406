import React from 'react';
import PanelWidthIcon from '@/shared/icons/PanelWidthIcon';
import PropertyList from '@/shared/elements/PropertyList/PropertyList';
import ColorsIcon from '@/shared/icons/ColorsIcon';
import PickerPopover from '@/shared/elements/PickerPopover/PickerPopover';
import MaterialIcon from '@/shared/icons/MaterialIcon';
import { Radio } from 'antd';
import IntusRadio from '@/shared/elements/Radio/IntusRadio';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import { getHexByName } from '@/components/WindowCreator/helpers/config';
import { IntusButton } from '@/shared/elements';

const PanelizationSettings = ({
  areSettingsDisabled,
}: {
  areSettingsDisabled: boolean;
}) => {
  const configData = useFetchWindowConfigQuery().data!;
  return (
    <div className="text-xs">
      <div className="flex justify-between px-3 min-h-8 items-center">
        <span className="font-medium leading-4">Panelization settings</span>
      </div>
      <div
        className={
          'flex flex-col border border-l-0 border-solid border-light-gray-20  overflow-y-auto text-dark-gray-100'
        }
      >
        <div className="flex items-center justify-start gap-1 px-3 pt-3">
          <PanelWidthIcon />
          <span className="font-medium leading-5">CORNER PANEL WIDTH</span>
        </div>
        <PropertyList
          alignValueLeft
          properties={[
            {
              name: 'Left',
              value: '523.87',
              isDisabled: areSettingsDisabled,
              isEditable: true,
              onEdit: (value: string) => {
                console.log(value);
              },
              onSubmit: () => {},
            },
            {
              name: 'Right',
              value: '523.87',
              isDisabled: areSettingsDisabled,
              isEditable: true,
              onEdit: (value: string) => {
                console.log(value);
              },
              onSubmit: () => {},
            },
          ]}
        />
      </div>
      <div className="text-dark-gray-100 pb-3 border-0 border-b border-solid border-light-gray-20 border-box">
        <div className="flex gap-1 mb-2 px-3 pt-3">
          <ColorsIcon />
          <div className="font-medium leading-5">COLORS</div>
        </div>
        <div className="font-light leading-5 mb-1 px-3">Outside color</div>
        <PickerPopover
          items={configData.colors.map((color) => ({
            id: color.name,
            name: color.description,
            preview: (
              <div
                className={`w-6 h-6 box-border border-solid border border-light-gray-20 rounded-full`}
                style={{
                  backgroundColor: getHexByName(configData.colors, color.name),
                }}
              />
            ),
          }))}
          initialValue={configData.baseWindow.outerColor}
          disabled={areSettingsDisabled}
          contentHeight={184}
          contentWidth={210}
        />
        <div className="font-light leading-5 my-1 px-3">Inside color</div>
        <PickerPopover
          items={configData.colors.map((color) => ({
            id: color.name,
            name: color.description,
            preview: (
              <div
                className={`w-6 h-6 box-border border-solid border border-light-gray-20 rounded-full`}
                style={{
                  backgroundColor: getHexByName(configData.colors, color.name),
                }}
              />
            ),
          }))}
          initialValue={configData.baseWindow.outerColor}
          disabled={areSettingsDisabled}
          contentHeight={184}
          contentWidth={210}
        />
      </div>
      <div className="flex flex-col py-3 gap-2 border-0 border-b border-solid border-light-gray-20 mb-1">
        <div className="flex gap-1 px-3">
          <MaterialIcon />
          <span className="font-medium leading-5">MATERIAL</span>
        </div>
        <PickerPopover
          initialValue="Aluminium"
          items={configData.panelMaterials.map((material) => ({
            name: material.description,
            id: material.name,
            description: material.additionalInfo,
            preview: (
              <img src={material.image} width={32} height={32} alt="material" />
            ),
          }))}
          searchable={false}
          disabled={areSettingsDisabled}
          contentWidth={226}
        />
      </div>
      <div className="pt-3 px-3">
        <div className="leading-5 mb-2">Panelization area</div>
        <Radio.Group
          disabled={areSettingsDisabled}
          className="flex flex-col gap-1 mb-3"
        >
          <IntusRadio value="Selected area">
            <span className="font-light leading-5">Selected area</span>
          </IntusRadio>
          <IntusRadio value="fullBuilding">
            <span className="font-light leading-5">Full building</span>
          </IntusRadio>
        </Radio.Group>
        <IntusButton
          disabled={areSettingsDisabled}
          block
          className="rounded-lg"
          id="PanelizationSettings__PanelizeButton"
        >
          Panelize
        </IntusButton>
      </div>
    </div>
  );
};

export default PanelizationSettings;
