import React, { useEffect, useMemo, useState } from 'react';
import { Layer, Stage } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';
import Konva from 'konva';

import { useKonvaHandlers } from '@/shared/hooks/useKonvaHandlers';
import { NodeType, SelectedNode, WindowPlacementData } from '@/models';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getModifiedWalls,
  getSelectedPlacedWindows,
  setHoveredPlacedWindow,
  setSelectedPlacedWindow,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';

import { getFacadeDesignerMode } from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { FacadeDesignerModes } from '@/models/shared.model';

import FacadeDesignerWallView from '@/components/FacadeDesigner/elements/FacadeDesignerWallView';
import { getMinMaxCoordinatesAtVector3 } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import {
  useFindNodeData,
  WallSearchResults,
} from '@/shared/hooks/useFindNodeData';
import { convertFlatVector3ToVectors } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';

import { getMultiplyRate } from '@/store/slices/canvasMapSlice';
import { convertMetersToMillimeters } from '@/shared/helpers/distance';
import { round } from 'mathjs';
import { convert2DPointsToDistanceInMeters } from '@/shared/helpers/metrics';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';

const FacadeDesignerContainer = ({
  selectedWalls,
  placementHeight,
  placementWidth,
}: {
  selectedWalls: SelectedNode[];
  placementHeight: number;
  placementWidth: number;
}) => {
  Konva.dragButtons = [2];

  const dispatch = useAppDispatch();
  const multiplyRate = useAppSelector(getMultiplyRate);
  const modifiedWalls = useAppSelector(getModifiedWalls);
  const { updateWallWindowPlacements } = useUpdateUserBuildingData();
  const selectedPlacedWindows = useAppSelector(getSelectedPlacedWindows);
  const { findDataForWall } = useFindNodeData();

  const facadeDesignerMode = useAppSelector(getFacadeDesignerMode);
  const isSelectionMode = facadeDesignerMode === FacadeDesignerModes.Selection;

  const { wheelHandler } = useKonvaHandlers({ maxZoom: 1, minZoom: 0.01 });

  useEffect(() => {
    if (!isSelectionMode) {
      dispatch(setSelectedPlacedWindow([]));
    }
  }, [isSelectionMode]);

  const wallsData: WallSearchResults[] = useMemo(() => {
    return selectedWalls.map((wall) => findDataForWall(wall.guid)!);
  }, [selectedWalls]);

  const minMaxWallsCoordinates = useMemo(
    () =>
      getMinMaxCoordinatesAtVector3(
        wallsData.map((wall) => convertFlatVector3ToVectors(wall.points)).flat()
      ),
    [wallsData]
  );

  const xDirection = wallsData[0].points[0][0] <= wallsData[0].points[1][0];
  const zDirection = wallsData[0].points[0][2] <= wallsData[0].points[1][2];

  const wallsTotalHeight = round(
    Number(
      convertMetersToMillimeters(
        (minMaxWallsCoordinates.max.y - minMaxWallsCoordinates.min.y) /
          multiplyRate
      )
    ),
    2
  );

  const wallsTotalWidth = round(
    Number(
      convertMetersToMillimeters(
        convert2DPointsToDistanceInMeters(
          [minMaxWallsCoordinates.max.x, minMaxWallsCoordinates.max.z],
          [minMaxWallsCoordinates.min.x, minMaxWallsCoordinates.min.z],
          multiplyRate
        )
      )
    ),
    2
  );

  const scaleX = (placementWidth * 0.9) / wallsTotalWidth;
  const scaleY = (placementHeight - 100) / wallsTotalHeight;
  const scale = Math.min(scaleX, scaleY);

  const [stageParams, setStageParams] = useState({
    scale,
    x: 0,
    y: 0,
  });

  useEffect(() => {
    //  Should not be called when width/height updates by user. Else it
    setStageParams({
      scale,
      x: placementWidth / 2 - (wallsTotalWidth * scale) / 2,
      y: placementHeight / 2 - (wallsTotalHeight * scale) / 2,
    });
  }, [modifiedWalls]);

  const handleWheel = (event: KonvaEventObject<WheelEvent>) => {
    const scaleData = wheelHandler(event);

    scaleData && setStageParams(scaleData);
  };

  const handleSaveWindowPlacements = (
    placements: WindowPlacementData[],
    wallData: WallSearchResults
  ) => {
    updateWallWindowPlacements({
      buildingGUID: wallData.parentNodes.find(
        (node) => node.type === NodeType.Building
      )!.guid,
      blockGUID: wallData.parentNodes.find(
        (node) => node.type === NodeType.Block
      )!.guid,
      storeyGUID: wallData.parentNodes.find(
        (node) => node.type === NodeType.Storey
      )!.guid,
      wallGUID: wallData.guid,
      windowPlacements: placements,
    });
  };

  const onKeydown = (event: KeyboardEvent) => {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      if (facadeDesignerMode === FacadeDesignerModes.Selection) {
        wallsData.forEach((wallData) => {
          const remainWindows = wallData.windowPlacements!.filter((wp) =>
            selectedPlacedWindows.every(
              (selectedWindow) =>
                selectedWindow.wallGUID !== wallData.guid ||
                selectedWindow.offset !== wp.offsetFromLeftEdge
            )
          );

          if (remainWindows.length !== wallData.windowPlacements?.length) {
            handleSaveWindowPlacements(remainWindows, wallData);
          }
        });

        dispatch(setSelectedPlacedWindow([]));
        dispatch(setHoveredPlacedWindow(null));
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeydown);
    return () => {
      document.removeEventListener('keydown', onKeydown);
    };
  }, [selectedPlacedWindows, wallsData]);

  return (
    <Stage
      onContextMenu={(e) => e.evt.preventDefault()}
      width={placementWidth}
      height={placementHeight}
      scaleX={stageParams.scale}
      scaleY={stageParams.scale}
      x={stageParams.x}
      y={stageParams.y}
      draggable
      onWheel={handleWheel}
      onDragEnd={() => {}}
      onDragMove={() => {}}
    >
      <Layer>
        {wallsData.map((wallData) => (
          <FacadeDesignerWallView
            startPoint={{
              x: minMaxWallsCoordinates[xDirection ? 'max' : 'min'].x,
              z: minMaxWallsCoordinates[zDirection ? 'max' : 'min'].z,
            }}
            scale={stageParams.scale}
            wallData={wallData}
            key={wallData.guid}
            minMaxWallsCoordinates={minMaxWallsCoordinates}
            onAddWindow={(data) => handleSaveWindowPlacements(data, wallData)}
            onMeasurementUpdate={(data) =>
              handleSaveWindowPlacements(data, wallData)
            }
          />
        ))}
      </Layer>
    </Stage>
  );
};

export default FacadeDesignerContainer;
