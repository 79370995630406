import React, { useMemo } from 'react';
import { FlatVector3, UserBuildingBlock, UserBuildingStorey } from '@/models';
import {
  createLine2,
  isPointsInOneLine,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { C_FatLineSelectedBorderMaterial } from '@/shared/materials';
import * as polyclip from 'polyclip-ts';
import { POLYGON_OFFSET_FACTOR_LEVELS } from '@/shared/constants';

const BlockContour = ({ block }: { block: UserBuildingBlock }) => {
  polyclip.setPrecision(1e-12);

  const contourMaterial = useMemo(() => {
    const material = C_FatLineSelectedBorderMaterial.clone();
    material.polygonOffsetFactor = POLYGON_OFFSET_FACTOR_LEVELS.HIGH;
    return material;
  }, []);

  const getEdgePoints = (storey: UserBuildingStorey): FlatVector3[] => {
    const points = storey.floor.points;

    const edgePoint: FlatVector3[] = [];

    for (let i = 0; i < points.length; i++) {
      const previousIndex = i === 0 ? points.length - 1 : i - 1;
      const nextIndex = i === points.length - 1 ? 0 : i + 1;

      const isInLine = isPointsInOneLine(
        [points[previousIndex][0], points[previousIndex][2]],
        [points[i][0], points[i][2]],
        [points[nextIndex][0], points[nextIndex][2]]
      );
      !isInLine && edgePoint.push(points[i]);
    }
    return edgePoint;
  };

  const generateVerticalContour = (
    point: FlatVector3,
    storeyCeilingPoint: FlatVector3
  ) => {
    return createLine2(
      [point, [point[0], storeyCeilingPoint[1], point[2]]].flat(),
      contourMaterial
    );
  };

  const verticalContour = useMemo(() => {
    return block.storeys
      .map((storey) =>
        getEdgePoints(storey).map((point) =>
          generateVerticalContour(point, storey.ceiling.points[0])
        )
      )
      .flat();
  }, [block]);

  const generateTopStoreyContour = (
    storey: UserBuildingStorey,
    nextStorey?: UserBuildingStorey
  ): FlatVector3[][] => {
    if (!nextStorey) return [storey.ceiling.points];

    const diff = polyclip.xor(
      [storey.floor.points.map((p) => [p[0], p[2]])] as polyclip.Geom,
      [nextStorey.floor.points.map((p) => [p[0], p[2]])] as polyclip.Geom
    );
    return diff
      .flat()
      .map((contour) =>
        contour.map((point) => [
          point[0],
          storey.ceiling.points[0][1],
          point[1],
        ])
      );
  };

  const generateBottomStoreyContour = (
    storey: UserBuildingStorey,
    previousStorey?: UserBuildingStorey
  ): FlatVector3[][] => {
    if (!previousStorey) {
      return [storey.floor.points];
    }
    return [];
  };

  const findStoreyContour = (
    storey: UserBuildingStorey,
    previousStorey?: UserBuildingStorey,
    nextStorey?: UserBuildingStorey
  ) => {
    return [
      generateTopStoreyContour(storey, nextStorey),
      generateBottomStoreyContour(storey, previousStorey),
    ].flat();
  };

  const contour = useMemo(() => {
    return block.storeys.map((storey, i) =>
      findStoreyContour(storey, block.storeys[i - 1], block.storeys[i + 1])
    );
  }, [block]);

  return (
    <group>
      {verticalContour.map((point, i) => (
        <primitive object={point} key={`vertical-contour_${i}`} />
      ))}

      {contour.map((edges, i) =>
        edges?.map((edge, j) => (
          <primitive
            object={createLine2(edge.flat(), contourMaterial)}
            key={`contour-point_${i}_${j}`}
          />
        ))
      )}
    </group>
  );
};

export default BlockContour;
