import { convertMillimetersToFtInch } from './distance';
import { round } from 'mathjs';

export function addSpacesToThousands(
  numberStr: string,
  isImperialUnits: boolean
) {
  return isImperialUnits && numberStr.includes("'")
    ? numberStr.replace(/(\d)(?=(\d{3})+(?='))/g, '$1 ')
    : numberStr.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function removeSpacesFromThousands(
  value: string,
  isImperialUnits: boolean
) {
  return isImperialUnits && value.includes("'")
    ? value.replace(/\s+(?=.*')/g, '')
    : value.replace(/\s/g, '');
}

export function getAreaUnit(isImperialUnits: boolean) {
  return isImperialUnits ? 'ft²' : 'm²';
}

export function limitValue(value: number, min: number, max: number): number {
  return round(Math.min(Math.max(value, min), max), 2);
}

export function sanitizeInputMetricValue(
  value: string,
  isImperialUnits: boolean,
  digitsAmount: number = 1,
  staticValue: boolean = false
) {
  if (staticValue) {
    return value.replace(/\D/g, '');
  }
  const sanitizedValue = isImperialUnits
    ? value.replace(/[^\d\s/'"-.]/g, '').replace(/\s+(?=.*')/g, '')
    : value.replace(/(?!^-)[^\d.]/g, '');

  return sanitizedValue.replace(
    /\.(\d*)/,
    (match, digitsAfterDot) => `.${digitsAfterDot.slice(0, digitsAmount)}`
  );
}

export const formatLimitValue = ({
  limit,
  isImperialUnits,
  staticValue,
}: {
  limit: number;
  isImperialUnits: boolean;
  staticValue: boolean;
}): string => {
  const formattedLimit =
    isImperialUnits && !staticValue
      ? convertMillimetersToFtInch(limit)
          .toString()
          .replace(/"/g, '\u2033')
          .replace(/'/g, '\u2032')
      : // Use Unicode because with style direction: rtl; regular double and single quotes are not displayed correctly
        limit.toFixed(0);

  return addSpacesToThousands(formattedLimit, isImperialUnits);
};

export const getNextCharacter = (c: string) => {
  return String.fromCharCode(c.charCodeAt(0) + 1);
};

export const getAlphabetIndex = (i: number) =>
  String.fromCharCode('A'.charCodeAt(0) + i);
