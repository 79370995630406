import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import { useParams } from 'react-router';
import { subscribe, unsubscribe } from '@/core/events';
import { MAKE_SCREENSHOT } from '@/core/event-names';
import { makeScreenshotDelay } from '@/shared/helpers/camera';
import { sleep } from '@/shared/helpers/sleep';
import ZeroStateFrame from './frames/ZeroStateFrame';
import EnvironmentFrame from './frames/EnvironmentFrame';
import SurroundingBuildingFrame from './frames/SurroundingBuildingFrame';
import SurroundingBuildingsFrame from './frames/SurroundingBuildingsFrame';
import ConstructionSiteFrame from './frames/ConstructionSiteFrame';
import clipping, { Ring } from 'polygon-clipping';
import { NodeType, SelectedNode, UnitSystemTypes } from '@/models';
import { area, polygon, Position } from '@turf/turf';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { useAppSelector } from '@/store/hooks';
import BuildingFrame from './frames/BuildingFrame';
import WallFrame from './frames/WallFrame';
import StoreyFrame from './frames/StoreyFrame';
import {
  getConstructionSiteCanvasCoordinates,
  getMultiplyRate,
} from '@/store/slices/canvasMapSlice';
import { ShapeUtils, Vector2 } from 'three';
import { formatAreaValue } from './propertyPanel-helpers';
import BlockFrame from './frames/BlockFrame';
import { getAreaInMeters } from '@/shared/helpers/metrics';
import { getBuildingsCoordinates } from '@/shared/helpers';
import { getIsFacadeDesignerModeEnabled } from '@/store/slices/canvasModesSlice';
import PanelizationSettings from './frames/PanelizationSettings';

const PropertyPanel = ({
  selectedNodes,
}: {
  selectedNodes: SelectedNode[];
}) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const isFacadeModeEnabled = useAppSelector(getIsFacadeDesignerModeEnabled);

  const { id } = useParams();
  const constructionSiteCanvasCoordinates = useAppSelector(
    getConstructionSiteCanvasCoordinates
  );
  const multiplyRate = useAppSelector(getMultiplyRate);
  const nodes = Object.values(selectedNodes);
  const areSelectedNodesTypesAreTheSame =
    nodes.length > 0 && nodes.every((node) => node.type === nodes[0].type);

  const unitSystem = useAppSelector(getProjectUnits(id!));

  const isImperial = unitSystem === UnitSystemTypes.Imperial;

  const projectData = useFetchProjectQuery(id!).data!;

  const constructionSite = projectData.environment.constructionSite;

  const constructionSiteArea = Number(
    area(polygon([constructionSite?.points as Position[]])).toFixed(2)
  );

  const selectedNode = Object.values(selectedNodes)[0];
  const selectedType = selectedNode?.type;
  const selectedNodesGUID = selectedNodes.map((node) => node.guid);

  const getOccupiedArea = () => {
    const coordinates: Ring = constructionSiteCanvasCoordinates!.map(
      (vector) => [vector[0], vector[2]]
    );

    const buildingFloorsCoordinates: Ring[] = getBuildingsCoordinates(
      projectData.buildings!
    ).map((c) =>
      c.map(
        (coordinates) => [coordinates[0], coordinates[2]] as [number, number]
      )
    );

    const occupiedPolygons = clipping.intersection(
      [coordinates],
      buildingFloorsCoordinates
    );

    const siteArea = Number(
      getAreaInMeters(constructionSiteCanvasCoordinates!, multiplyRate)
    );

    const occupiedArea = Number(
      occupiedPolygons
        .reduce((acc, polygon) => {
          return (
            acc +
            ShapeUtils.area(polygon[0].map((v) => new Vector2(...v))) /
              Math.pow(multiplyRate, 2)
          );
        }, 0)
        .toFixed(2)
    );

    const usageRate = Number(((occupiedArea / siteArea) * 100).toFixed(2));

    return { occupiedArea, usageRate };
  };

  const getFrame = useCallback(() => {
    let contentFrame: ReactElement;

    if (areSelectedNodesTypesAreTheSame && !isFacadeModeEnabled) {
      switch (selectedType) {
        case NodeType.Environment:
          contentFrame = (
            <EnvironmentFrame
              siteArea={formatAreaValue(constructionSiteArea, isImperial)}
              siteUse={{
                areaUsed: formatAreaValue(
                  getOccupiedArea().occupiedArea,
                  isImperial
                ),
                usageRate: getOccupiedArea().usageRate,
              }}
            />
          );
          break;
        case NodeType.SurroundingBuilding:
          contentFrame = (
            <SurroundingBuildingFrame envBuildingsGUID={selectedNodesGUID} />
          );
          break;
        case NodeType.SurroundingBuildings:
          contentFrame = <SurroundingBuildingsFrame />;
          break;
        case NodeType.ConstructionSite:
          contentFrame = (
            <ConstructionSiteFrame
              area={formatAreaValue(constructionSiteArea, isImperial)}
              siteUse={{
                siteArea: formatAreaValue(
                  getOccupiedArea().occupiedArea,
                  isImperial
                ),
                usageRate: getOccupiedArea().usageRate,
              }}
              isImperialUnits={isImperial}
            />
          );
          break;
        case NodeType.Building:
          contentFrame = <BuildingFrame buildingsGUID={selectedNodesGUID} />;
          break;
        case NodeType.Block:
          contentFrame = <BlockFrame blocksGUIDs={selectedNodesGUID} />;
          break;
        case NodeType.Storey:
          contentFrame = <StoreyFrame storeysGUID={selectedNodesGUID} />;
          break;
        case NodeType.Wall:
          contentFrame = <WallFrame wallsGUID={selectedNodesGUID} />;
          break;
        default:
          contentFrame = <ZeroStateFrame />;
          break;
      }
    } else if (isFacadeModeEnabled) {
      contentFrame = (
        <PanelizationSettings
          areSettingsDisabled={
            !areSelectedNodesTypesAreTheSame || selectedType !== NodeType.Wall
          }
        />
      );
    } else {
      contentFrame = <ZeroStateFrame />;
    }

    return contentFrame;
  }, [selectedNodes, unitSystem]);

  useEffect(() => {
    subscribe(MAKE_SCREENSHOT, toggleAnimation);
    return () => {
      unsubscribe(MAKE_SCREENSHOT, toggleAnimation);
    };
  }, []);

  const toggleAnimation = async () => {
    setIsAnimating(true);
    await sleep(makeScreenshotDelay);
    setIsAnimating(false);
  };

  return (
    <div
      className={`absolute right-0 top-0 h-full z-20 flex flex-col overflow-x-hidden overflow-y-hidden max-h-full w-[220px] border bg-white border-light-gray-20 border-solid border-t-0 duration-500 ${isAnimating ? 'opacity-0' : 'opacity-100'}`}
    >
      {getFrame()}
    </div>
  );
};

export default PropertyPanel;
