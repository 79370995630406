import {
  DistanceInput,
  FlatVector3,
  MetricLimits,
  NodeType,
  UnitSystemTypes,
  UserBuildingBlock,
  UserBuildingCoordinatesObject,
  UserBuildingStorey,
  UserBuildingSurface,
} from '@/models';
import { useAppSelector } from '@/store/hooks';
import { getMultiplyRate } from '@/store/slices/canvasMapSlice';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { useParams } from 'react-router';
import {
  BlockSearchResults,
  useFindNodeData,
  WallSearchResults,
} from './useFindNodeData';
import {
  calculateCommonArea,
  calculateCommonMetric,
  formatAreaValue,
  getCommonValue,
} from '@/routes/dashboard/projects/project/CanvasExternalElements/PropertyPanel/propertyPanel-helpers';
import {
  getAreaInMeters,
  getFacadesArea,
  getObjectDimensions,
  getStoreyHeight,
  getWallArea,
  getWallWidth,
} from '../helpers/metrics';
import {
  convertFtInchToMillimeters,
  convertMetersToMillimeters,
  convertMillimetersToFtInch,
  convertMillimetersToMeters,
} from '../helpers/distance';
import { convertFlatVector3ToVectors } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import * as THREE from 'three';
import { updateSections } from '@/shared/helpers/rectangle-mode';
import { convertInputValueToMillimeters } from '@/shared/helpers/directional-input';
import {
  addSpacesToThousands,
  getAlphabetIndex,
  limitValue,
  removeSpacesFromThousands,
} from '../helpers/format-data';
import { uuidv7 } from 'uuidv7';
import { cloneDeep, omit } from 'lodash';
import {
  convertBufferGeometryTo3DVectorList,
  getExistingCoordinates,
  getExtendedVector,
  getXYZ,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';

interface framePropertiesProps {
  metricOnly?: boolean;
}
const useFrameProperties = (props?: framePropertiesProps) => {
  const { id } = useParams();
  const multiplyRate = useAppSelector(getMultiplyRate);
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const { findDataForStorey, findDataForWall } = useFindNodeData();

  const isImperialUnits = unitSystem === UnitSystemTypes.Imperial;

  const getBuildingDimensionsMetric = (
    blocks: BlockSearchResults[],
    dimension: DistanceInput.Width | DistanceInput.Length
  ) => {
    const buildingDimensions = blocks.map((block) =>
      getObjectDimensions(
        block.groundFloorPoints,
        multiplyRate,
        isImperialUnits
      )
    );

    return dimension === DistanceInput.Width
      ? calculateCommonMetric({
          nodes: buildingDimensions,
          calculateMetricFunction: (block) => block.width,
        })
      : calculateCommonMetric({
          nodes: buildingDimensions,
          calculateMetricFunction: (block) => block.length,
        });
  };

  const getBlockStoreysCountMetric = (blocks: BlockSearchResults[]) =>
    getCommonValue(blocks.map((block) => block.storeysCount.toString()));

  const formatHeight = (heightInMeters: string) => {
    const heightInMillimeters = convertMetersToMillimeters(heightInMeters);
    return addSpacesToThousands(
      isImperialUnits
        ? convertMillimetersToFtInch(heightInMillimeters)
        : heightInMillimeters,
      isImperialUnits
    );
  };

  const getCommonHeight = (heights: string[]) => {
    const commonValue = getCommonValue(heights);
    return commonValue === '-' ? commonValue : formatHeight(commonValue);
  };

  const getStoreyHeightInMillimeters = (storey: UserBuildingStorey) =>
    convertMetersToMillimeters(getStoreyHeight(storey, multiplyRate));

  const getStoreysHeight = (storeys: UserBuildingStorey[]) =>
    storeys.map((storey) => getStoreyHeight(storey, multiplyRate));

  const getFloorHeightMetric = (storeys: UserBuildingStorey[]) =>
    getCommonHeight(getStoreysHeight(storeys));

  const getFloorHeightInBlock = (blocks: UserBuildingBlock[]) =>
    getCommonHeight(blocks.flatMap((block) => getStoreysHeight(block.storeys)));

  const getBlockHeightMetric = (blocks: UserBuildingBlock[]) => {
    const totalHeight = convertMetersToMillimeters(
      blocks
        .flatMap((block) =>
          block.storeys.map((storey) =>
            Number(getStoreyHeight(storey, multiplyRate))
          )
        )
        .reduce((total, height) => total + height, 0)
        .toFixed(4)
    );

    return isImperialUnits
      ? convertMillimetersToFtInch(totalHeight)
      : addSpacesToThousands(totalHeight.toString(), isImperialUnits);
  };

  const getFacadesAreaMetricForBlocks = (blocks: BlockSearchResults[]) => {
    const wallsGUID = blocks.flatMap((block) =>
      block.childNodes
        .filter((node) => node.type === NodeType.Wall)
        .map((node) => node.guid)
    );

    const wallsData = wallsGUID.map((guid) => {
      return findDataForWall(guid)!;
    });

    const facadesArea = getFacadesArea({
      walls: wallsData,
      multiplyRate,
    });

    return formatAreaValue(facadesArea, isImperialUnits);
  };

  const getGrossInternalAreaForBlocks = (blocks: BlockSearchResults[]) => {
    const storeysGUID = blocks.flatMap((block) =>
      block.childNodes
        .filter((node) => node.type === NodeType.Storey)
        .map((node) => node.guid)
    );

    const storeysData = storeysGUID.map((guid) => {
      return findDataForStorey(guid)!;
    });

    return calculateCommonArea({
      nodes: storeysData,
      calculateAreaFunction: (storey) =>
        Number(getAreaInMeters(storey.ceiling.points, multiplyRate)),
      isImperialUnits,
    });
  };

  const getFacadesAreaMetricForStoreys = (storeys: UserBuildingStorey[]) => {
    return calculateCommonArea({
      nodes: storeys,
      calculateAreaFunction: (storey) =>
        getFacadesArea({
          walls: storey.walls,
          multiplyRate,
        }),
      isImperialUnits,
    });
  };

  const getGrossInternalAreaMetricForStoreys = (
    storeys: UserBuildingStorey[]
  ) => {
    return calculateCommonArea({
      nodes: storeys,
      calculateAreaFunction: (storey) =>
        Number(getAreaInMeters(storey.ceiling.points, multiplyRate)),
      isImperialUnits,
    });
  };

  const getWallWidthMetric = (walls: WallSearchResults[]) => {
    const wallWidths = walls.map((wall) =>
      getWallWidth(wall.points, multiplyRate).toString()
    );

    const commonWallWidthInMeters = getCommonValue(wallWidths);

    if (commonWallWidthInMeters === '-') return commonWallWidthInMeters;
    const wallWidthInMillimeters = convertMetersToMillimeters(
      commonWallWidthInMeters
    );
    return isImperialUnits && !props?.metricOnly
      ? addSpacesToThousands(
          convertMillimetersToFtInch(wallWidthInMillimeters),
          isImperialUnits
        )
      : addSpacesToThousands(
          Number(wallWidthInMillimeters).toFixed(0),
          isImperialUnits
        );
  };

  const getFacadesAreaMetricForWalls = (walls: WallSearchResults[]) => {
    const calculateWallArea = (wall: WallSearchResults) => {
      return Number(getWallArea(wall.points, multiplyRate));
    };

    return calculateCommonArea({
      nodes: walls,
      calculateAreaFunction: calculateWallArea,
      isImperialUnits,
    });
  };

  const createNewStoreyFromPreviousStorey = (
    lastStorey: UserBuildingStorey,
    storeyNumber: number
  ): UserBuildingStorey => {
    const lastStoreyHeight =
      lastStorey.ceiling.points[0][1] - lastStorey.floor.points[0][1];
    return {
      guid: uuidv7(),
      storeyNumber,
      name: `Floor ${storeyNumber}`,
      floor: {
        points: updatePointsHeight(lastStorey.floor.points, lastStoreyHeight),
        guid: uuidv7(),
        userData: {},
      },
      ceiling: {
        points: updatePointsHeight(lastStorey.ceiling.points, lastStoreyHeight),
        guid: uuidv7(),
        userData: {},
      },
      walls: lastStorey.walls.map((wall, i) => ({
        points: updatePointsHeight(wall.points, lastStoreyHeight),
        guid: uuidv7(),
        userData: {},
        name: `Wall ${getAlphabetIndex(i)}`,
      })),
      userData: {},
    } as UserBuildingStorey;
  };

  const changeStoreysNumberInBlock = (
    value: string,
    userBuildingBlock: UserBuildingBlock
  ) => {
    const validatedValue = limitValue(
      Number(value),
      MetricLimits.FloorsMin,
      MetricLimits.FloorsMax
    );

    const targetStoreyCount = Number(validatedValue);

    const currentStoreyCount = userBuildingBlock.storeys.length;
    const updatedBlock = cloneDeep(userBuildingBlock);

    if (targetStoreyCount < currentStoreyCount) {
      updatedBlock.storeys = userBuildingBlock.storeys.slice(
        0,
        targetStoreyCount
      );
    } else if (targetStoreyCount > currentStoreyCount) {
      for (let i = currentStoreyCount; i < targetStoreyCount; i++) {
        const lastStorey = updatedBlock.storeys[i - 1];
        const newStorey = createNewStoreyFromPreviousStorey(lastStorey, i + 1);
        updatedBlock.storeys.push(newStorey);
      }
    }

    return updatedBlock;
  };

  const updateBuildingWidth = (
    val: string,
    building: UserBuildingCoordinatesObject
  ) => {
    const valueWithSpaces = removeSpacesFromThousands(val, isImperialUnits);
    const width =
      convertMillimetersToMeters(
        convertInputValueToMillimeters(valueWithSpaces, !isImperialUnits)
      ) * multiplyRate;
    const centerLineVectors = convertFlatVector3ToVectors(
      building.centerLineCoordinates!.points
    );
    const sections: THREE.Mesh[] = [];
    let floorShape: THREE.Mesh = null!;
    let processingNewSection: boolean = false;
    const generateFatCenterLine = () => {};
    const updateContour = () => {};

    for (let i = 0; i < centerLineVectors.length - 1; i++) {
      updateSections({
        closeSection: false,
        nextPoint: centerLineVectors[i + 1],
        sections,
        width,
        generateFatCenterLine,
        updateContour,
        setProcessingNewSection: (val) => (processingNewSection = val),
        setFloorShape: (val) => (floorShape = val),
        processingSection: false,
        floor: floorShape,
        activeCenterLine: [centerLineVectors[i], centerLineVectors[i + 1]],
        previousCenterLinePoint:
          i === 0 ? new THREE.Vector3() : centerLineVectors[i - 1],
      });
      updateSections({
        closeSection: false,
        nextPoint: centerLineVectors[i + 1],
        sections,
        width,
        processingSection: true,
        floor: floorShape,
        generateFatCenterLine,
        updateContour,
        setProcessingNewSection: (val) => (processingNewSection = val),
        setFloorShape: (val) => (floorShape = val),
        activeCenterLine: [centerLineVectors[i], centerLineVectors[i + 1]],
        previousCenterLinePoint:
          i === 0 ? new THREE.Vector3() : centerLineVectors[i - 1],
      });

      if (i < centerLineVectors.length - 2) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        processingNewSection = false;
      }
    }

    floorShape.geometry.setFromPoints([
      ...getExistingCoordinates(floorShape),
      getExistingCoordinates(floorShape)[0],
    ]);

    const firstFloorCoordinates: FlatVector3[] =
      convertBufferGeometryTo3DVectorList(
        floorShape.geometry,
        floorShape.geometry.getAttribute('position').count
      ).map((vector) => getXYZ(vector));

    const updatedBlock = cloneDeep(building.blocks[0]);
    let currentHeight = 0;

    for (
      let floorIndex = 0;
      floorIndex < building.blocks[0].storeys.length;
      floorIndex++
    ) {
      const storeyHeight =
        building.blocks[0].storeys[floorIndex].ceiling.points[0][1] -
        building.blocks[0].storeys[floorIndex].floor.points[0][1];

      const floorPoints = firstFloorCoordinates.map(([x, y, z]) => [
        x,
        y + currentHeight,
        z,
      ]);
      const ceilingPoints = firstFloorCoordinates.map(([x, y, z]) => [
        x,
        y + currentHeight + storeyHeight,
        z,
      ]);

      const allWalls = [];
      for (let i = 0; i < floorPoints.length - 1; i++) {
        const wall = [
          floorPoints[i],
          floorPoints[i + 1],
          ceilingPoints[i + 1],
          ceilingPoints[i],
        ];
        allWalls.push(wall);
      }

      updatedBlock.storeys[floorIndex] = {
        ...omit(updatedBlock.storeys[floorIndex], 'id'),
        ceiling: {
          ...omit(updatedBlock.storeys[floorIndex].ceiling, 'id'),
          points: ceilingPoints,
        },
        floor: {
          ...omit(updatedBlock.storeys[floorIndex].floor, 'id'),
          points: floorPoints,
        },
        walls: allWalls.map((wallPoints, index) => ({
          ...omit(updatedBlock.storeys[floorIndex].walls[index], 'id'),
          points: wallPoints,
        })),
      } as UserBuildingStorey;

      currentHeight += storeyHeight;
    }

    return updatedBlock;
  };

  const updatePointsHeight = (
    points: FlatVector3[],
    heightOffset: number
  ): FlatVector3[] => {
    return points.map((point) => {
      const updatedPoint: FlatVector3 = [
        point[0],
        point[1] + heightOffset,
        point[2],
      ];
      return updatedPoint;
    });
  };

  const updateFirstFloorCoordinatesForRectangleBuilding = ({
    value,
    userBuildingBlock,
    dimension,
  }: {
    value: string;
    userBuildingBlock: UserBuildingBlock;
    dimension: DistanceInput.Width | DistanceInput.Length;
  }) => {
    const valueWithSpaces = removeSpacesFromThousands(value, isImperialUnits);
    const firstStorey = userBuildingBlock.storeys[0];
    const meterValue =
      convertMillimetersToMeters(
        isImperialUnits
          ? convertFtInchToMillimeters(valueWithSpaces)
          : valueWithSpaces
      ) * multiplyRate;

    const [v1, v2, v3, v4, v5] = convertFlatVector3ToVectors(
      firstStorey.floor.points
    );

    let updatedVectors;
    if (dimension === DistanceInput.Width) {
      updatedVectors = [
        v1,
        getExtendedVector(v1, v2, meterValue),
        getExtendedVector(v4, v3, meterValue),
        v4,
        v5,
      ];
    } else {
      updatedVectors = [
        v1,
        v2,
        getExtendedVector(v2, v3, meterValue),
        getExtendedVector(v1, v4, meterValue),
        v5,
      ];
    }

    return updatedVectors.map((vector) => getXYZ(vector));
  };

  const changeDimensionInRectangleBuilding = ({
    value,
    userBuildingBlock,
    dimension,
  }: {
    value: string;
    userBuildingBlock: UserBuildingBlock;
    dimension: DistanceInput.Width | DistanceInput.Length;
  }) => {
    const firstFloorCoordinates =
      updateFirstFloorCoordinatesForRectangleBuilding({
        value,
        userBuildingBlock,
        dimension,
      });

    const updatedBlock = cloneDeep(userBuildingBlock);

    let currentHeight = 0;

    for (
      let floorIndex = 0;
      floorIndex < userBuildingBlock.storeys.length;
      floorIndex++
    ) {
      const storeyHeight =
        userBuildingBlock.storeys[floorIndex].ceiling.points[0][1] -
        userBuildingBlock.storeys[floorIndex].floor.points[0][1];

      const floorPoints = firstFloorCoordinates.map(([x, y, z]) => [
        x,
        y + currentHeight,
        z,
      ]);
      const ceilingPoints = firstFloorCoordinates.map(([x, y, z]) => [
        x,
        y + currentHeight + storeyHeight,
        z,
      ]);

      const allWalls = [];
      for (let i = 0; i < floorPoints.length - 1; i++) {
        const wall = [
          floorPoints[i],
          floorPoints[i + 1],
          ceilingPoints[i + 1],
          ceilingPoints[i],
        ];
        allWalls.push(wall);
      }

      updatedBlock.storeys[floorIndex] = {
        ...omit(updatedBlock.storeys[floorIndex], 'id'),
        ceiling: {
          ...omit(updatedBlock.storeys[floorIndex].ceiling, 'id'),
          points: ceilingPoints,
        },
        floor: {
          ...omit(updatedBlock.storeys[floorIndex].floor, 'id'),
          points: floorPoints,
        },
        walls: allWalls.map((wallPoints, index) => ({
          ...omit(updatedBlock.storeys[floorIndex].walls[index], 'id'),
          points: wallPoints,
        })),
      } as UserBuildingStorey;

      currentHeight += storeyHeight;
    }

    return updatedBlock;
  };

  const updateFloorHeightInStorey = (
    height: string,
    storey: UserBuildingStorey
  ) => {
    const valueWithSpaces = removeSpacesFromThousands(height, isImperialUnits);
    const formattedHeight =
      convertMillimetersToMeters(
        convertInputValueToMillimeters(valueWithSpaces, !isImperialUnits)
      ) * multiplyRate;
    const ceilingPoints: FlatVector3[] = updatePointsHeight(
      storey.floor.points,
      Number(formattedHeight)
    );
    const updatedWalls: UserBuildingSurface[] = storey.walls.map((wall) => ({
      ...wall,
      points: wall.points.map(([x, y, z], i) => [
        x,
        i < 2 ? y : ceilingPoints[0][1],
        z,
      ]),
    }));

    return {
      ...storey,
      ceiling: {
        ...storey.ceiling,
        points: ceilingPoints,
      },
      walls: updatedWalls,
    };
  };

  const updateStoreyPointsAccordingToNewHeight = (
    height: string,
    currentStorey: UserBuildingStorey,
    previousStorey: UserBuildingStorey
  ) => {
    const previousStoreyHeight =
      previousStorey.ceiling.points[0][1] - previousStorey.floor.points[0][1];

    const updatedFloorPoints: FlatVector3[] = currentStorey.floor.points.map(
      (point) => [point[0], previousStorey.ceiling.points[0][1], point[2]]
    );

    const updatedCeilingPoints = updatePointsHeight(
      updatedFloorPoints,
      previousStoreyHeight
    );

    const updatedWalls = currentStorey.walls.map((wall) => ({
      ...wall,
      points: wall.points.map((point, index) => [
        point[0],
        index < 2 ? updatedFloorPoints[0][1] : updatedCeilingPoints[0][1],
        point[2],
      ]),
    }));

    return {
      ...currentStorey,
      floor: {
        ...currentStorey.floor,
        points: updatedFloorPoints,
      },
      ceiling: {
        ...currentStorey.ceiling,
        points: updatedCeilingPoints,
      },
      walls: updatedWalls,
    } as UserBuildingStorey;
  };

  const updateFloorHeightInBlock = (
    height: string,
    userBuildingBlock: UserBuildingBlock
  ): UserBuildingBlock => {
    const updatedBlock = cloneDeep(userBuildingBlock);
    updatedBlock.storeys[0] = updateFloorHeightInStorey(
      height,
      userBuildingBlock.storeys[0]
    );

    for (let i = 1; i < updatedBlock.storeys.length; i++) {
      updatedBlock.storeys[i] = updateStoreyPointsAccordingToNewHeight(
        height,
        updatedBlock.storeys[i],
        updatedBlock.storeys[i - 1]
      );
    }

    return updatedBlock;
  };

  const updateFloorHeightForSelectedStoreyInBlock = (
    height: string,
    updatedStoreyCount: number,
    userBuildingBlock: UserBuildingBlock
  ) => {
    const updatedBlock = cloneDeep(userBuildingBlock);

    updatedBlock.storeys[updatedStoreyCount - 1] = updateFloorHeightInStorey(
      height,
      updatedBlock.storeys[updatedStoreyCount - 1]
    );

    for (let i = updatedStoreyCount; i < updatedBlock.storeys.length; i++) {
      const previousStorey = updatedBlock.storeys[i - 1];
      const currentStorey = updatedBlock.storeys[i];

      const originalHeightOfCurrentStorey =
        currentStorey.ceiling.points[0][1] - currentStorey.floor.points[0][1];

      const floorPoints: FlatVector3[] = currentStorey.floor.points.map(
        (point) => [point[0], previousStorey.ceiling.points[0][1], point[2]]
      );

      const ceilingPoints: FlatVector3[] = updatePointsHeight(
        floorPoints,
        originalHeightOfCurrentStorey
      );

      const updatedWalls: UserBuildingSurface[] = currentStorey.walls.map(
        (wall) => ({
          ...wall,
          points: wall.points.map((point, index) => [
            point[0],
            index < 2 ? floorPoints[index][1] : ceilingPoints[0][1],
            point[2],
          ]),
        })
      );

      updatedBlock.storeys[i] = {
        ...currentStorey,
        floor: {
          ...currentStorey.floor,
          points: floorPoints,
        },
        ceiling: {
          ...currentStorey.ceiling,
          points: ceilingPoints,
        },
        walls: updatedWalls,
      };
    }

    return updatedBlock;
  };

  return {
    getBuildingDimensionsMetric,
    getFloorHeightMetric,
    getBlockStoreysCountMetric,
    getBlockHeightMetric,
    getStoreyHeightInMillimeters,
    getFloorHeightInBlock,
    getFacadesAreaMetricForBlocks,
    getGrossInternalAreaForBlocks,
    getFacadesAreaMetricForStoreys,
    getGrossInternalAreaMetricForStoreys,
    getWallWidthMetric,
    getFacadesAreaMetricForWalls,
    updateBuildingWidth,
    changeStoreysNumberInBlock,
    changeDimensionInRectangleBuilding,
    updateFloorHeightInBlock,
    updateFloorHeightInStorey,
    updateFloorHeightForSelectedStoreyInBlock,
  };
};

export default useFrameProperties;
