import React, { useEffect } from 'react';
import WindowCard from '@/shared/elements/WindowCard/WindowCard';
import { useParams } from 'react-router';
import { useFetchWindowsQuery } from '@/store/apis/windowApi';
import { IntusButton } from '@/shared/elements';
import { PlusOutlined } from '@ant-design/icons';
import Tooltip from '@/shared/elements/Tooltip/Tooltip';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getLeftPanelWidth } from '@/store/slices/sharedSlice';
import { publish } from '@/core/events';
import { OPEN_WINDOW_CREATOR } from '@/core/event-names';
import { getCanvasMode } from '@/store/slices/canvasModesSlice';
import { CanvasActionsModes, NodeType } from '@/models';
import { getFacadeDesignerMode } from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { FacadeDesignerModes } from '@/models/shared.model';
import {
  isSomeWindowFromLibrarySelected,
  resetSelectedWindowFromLibrary,
  setSelectedWindowFromLibrary,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { SavedWindow } from '@/components/WindowCreator/models/konva-model';
import { useFindNodeData } from '@/shared/hooks/useFindNodeData';
import { getSelectedNodes } from '@/store/slices/canvasBuildingSlice';

const LibraryPanel = () => {
  const { findDataForWall } = useFindNodeData();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const windowsData = useFetchWindowsQuery(id!).data!;
  const leftPanelWidth = useAppSelector(getLeftPanelWidth);
  const isDesignerMode =
    useAppSelector(getCanvasMode) === CanvasActionsModes.facadeDesigner;
  const facadeDesignerMode = useAppSelector(getFacadeDesignerMode);
  const isWindowFromLibrarySelected = useAppSelector(
    isSomeWindowFromLibrarySelected
  );
  const selectedNodes = Object.values(useAppSelector(getSelectedNodes));
  const hasPlacedWindows = selectedNodes
    .filter((node) => node.type === NodeType.Wall)
    .map((node) => !!findDataForWall(node.guid)?.windowPlacements?.length)
    .some((v) => v);

  const showTooltip =
    !hasPlacedWindows &&
    isDesignerMode &&
    facadeDesignerMode === FacadeDesignerModes.WindowPlacement &&
    !isWindowFromLibrarySelected;

  const tooltipText = (
    <span>
      Select a window <br />
      from the Library to place
    </span>
  );

  useEffect(() => {
    facadeDesignerMode === FacadeDesignerModes.Selection &&
      dispatch(resetSelectedWindowFromLibrary());
  }, [facadeDesignerMode]);

  const handleOpenWindowCreator = () => {
    publish(OPEN_WINDOW_CREATOR);
  };

  const handleSelect = (window: SavedWindow) => {
    dispatch(setSelectedWindowFromLibrary(window));
  };

  return (
    <>
      {showTooltip && (
        <Tooltip
          title={tooltipText}
          placement={'right'}
          open={true}
          overlayStyle={{
            inset: `35px auto auto ${leftPanelWidth + 13}px`,
            zIndex: 10,
          }}
          overlayInnerStyle={{
            textAlign: 'start',
          }}
        >
          <div
            className="absolute top-3"
            style={{
              left: leftPanelWidth - 3,
            }}
          />
        </Tooltip>
      )}

      {isDesignerMode && (
        <div className="p-2 bg-white sticky top-0 z-30">
          <IntusButton
            small
            isActive={true}
            onClick={handleOpenWindowCreator}
            block
            type="default"
            icon={<PlusOutlined />}
            id="LibraryPanel__new-window-type-button"
          >
            New window type
          </IntusButton>
        </div>
      )}

      {windowsData.map((windowData) => (
        <WindowCard
          isDesignerMode={isDesignerMode}
          facadeDesignerMode={facadeDesignerMode}
          key={windowData.id}
          windowData={windowData}
          onSelect={handleSelect}
        />
      ))}
    </>
  );
};

export default LibraryPanel;
