import React, { useEffect, useState } from 'react';
import * as THREE from 'three';
import { flatten } from 'lodash';

import {
  DistanceInput,
  FlatVector3,
  UserBuildingBlock,
  UserBuildingSurface,
} from '@/models';
import {
  ExtendedMinMaxCoordinatesPairs,
  getCenterFromFlatVectorsArray,
  getMinMaxCoordinatesAtVector3,
  isPointsInOneLine,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import {
  convertFlatVector3ToVector,
  convertFlatVector3ToVectors,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import SplitFaces from '@/routes/dashboard/projects/project/UserBuilding/components/SplitTool/SplitFaces';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getMultiplyRate } from '@/store/slices/canvasMapSlice';
import { convertMetersToMillimeters } from '@/shared/helpers/distance';
import {
  getProcessingEntity,
  setDirectionalInputValues,
} from '@/store/slices/canvasExternalElementsSlice';

interface CutFaceProps {
  block: UserBuildingBlock;
  mousePosition: THREE.Vector3 | null;
  currentWall: UserBuildingSurface | null;
  setCutFaceWallPoints: (v: FlatVector3[]) => void;
  cutFaceWallPoints: FlatVector3[] | undefined;
}

const CutFace: React.FC<CutFaceProps> = ({
  block,
  mousePosition,
  currentWall,
  cutFaceWallPoints,
  setCutFaceWallPoints,
}) => {
  const [minMax, setMinMax] = useState<ExtendedMinMaxCoordinatesPairs>();
  const [previousActiveWall, setPreviousActiveWall] = useState<string>();
  const multiplyRate = useAppSelector(getMultiplyRate);
  const dispatch = useAppDispatch();
  const processingEntity = useAppSelector(getProcessingEntity);

  const findFacadeWalls = () => {
    if (!mousePosition) return;

    const facadeWalls: {
      left: FlatVector3[];
      right: FlatVector3[];
    }[] = [];
    let activeWall: UserBuildingSurface;
    for (let i = 0; i < block.storeys.length; i++) {
      const storey = block.storeys[i];
      const index = storey.walls.findIndex((wall) =>
        isPointsInOneLine(
          [wall.points[0][0], wall.points[0][2]],
          [mousePosition.x, mousePosition.z],
          [wall.points[1][0], wall.points[1][2]]
        )
      );

      if (index === -1) continue;

      //this is necessary to correct the close distance and method isPointsInOneLine, to prevent a lot of unnecessary recalculations
      setPreviousActiveWall(currentWall?.guid);

      activeWall = storey.walls[index];

      let leftSideWall = storey.walls[index];

      //collect all the left side walls from the active wall
      for (let j = index; j < storey.walls.length; j++) {
        const nextWallPoints = storey.walls[j].points;
        const isNextWallInLine = isPointsInOneLine(
          [leftSideWall.points[0][0], leftSideWall.points[0][2]],
          [nextWallPoints[0][0], nextWallPoints[0][2]],
          [nextWallPoints[1][0], nextWallPoints[1][2]]
        );
        if (isNextWallInLine) {
          leftSideWall = storey.walls[j];
        }
      }
      let rightSideWall = storey.walls[index];

      //collect all the right side walls from the active wall
      for (let j = index; j > 0; j--) {
        const nextWallPoints = storey.walls[j].points;
        const isNextWallInLine = isPointsInOneLine(
          [rightSideWall.points[1][0], rightSideWall.points[1][2]],
          [rightSideWall.points[0][0], rightSideWall.points[0][2]],
          [nextWallPoints[0][0], nextWallPoints[0][2]]
        );
        if (isNextWallInLine) {
          rightSideWall = storey.walls[j];
        }
      }
      facadeWalls.push({
        left: [leftSideWall.points[1], leftSideWall.points[2]],
        right: [rightSideWall.points[0], rightSideWall.points[3]],
      });
    }

    if (!facadeWalls.length) return;

    const center = getCenterFromFlatVectorsArray(activeWall!.points);

    const leftPoints: FlatVector3[] = flatten(
      facadeWalls.map((wall) => wall.left)
    );

    //find farthest left point
    const farthestLeftWall: FlatVector3 = leftPoints.reduce((acc, curr) => {
      const leftDistance = convertFlatVector3ToVector(curr).distanceTo(center);
      return leftDistance > convertFlatVector3ToVector(acc).distanceTo(center)
        ? curr
        : acc;
    }, leftPoints[0]);

    const rightPoints: FlatVector3[] = flatten(
      facadeWalls.map((wall) => wall.right)
    );

    //find farthest right point
    const farthestRightWall: FlatVector3 = rightPoints.reduce((acc, curr) => {
      const rightDistance = convertFlatVector3ToVector(curr).distanceTo(center);
      return rightDistance > convertFlatVector3ToVector(acc).distanceTo(center)
        ? curr
        : acc;
    }, rightPoints[0]);

    const minY = block.storeys[0].floor.points[0][1];
    const maxY = block.storeys[block.storeys.length - 1].ceiling.points[0][1];

    const points: FlatVector3[] = [
      [farthestRightWall[0], minY, farthestRightWall[2]],
      [farthestLeftWall[0], minY, farthestLeftWall[2]],
      [farthestLeftWall[0], maxY, farthestLeftWall[2]],
      [farthestRightWall[0], maxY, farthestRightWall[2]],
    ];

    const minMax = getMinMaxCoordinatesAtVector3(
      convertFlatVector3ToVectors(
        flatten(facadeWalls.map((wall) => [...wall.left, ...wall.right]))
      )
    );

    setMinMax({
      max: {
        ...minMax.max,
        y: block.storeys[block.storeys.length - 1].ceiling.points[0][1],
      },
      min: {
        ...minMax.min,
        y: block.storeys[0].floor.points[0][1],
      },
    });
    setCutFaceWallPoints(points);
    const firstPoint = convertFlatVector3ToVector(farthestLeftWall);
    const secondPoint = convertFlatVector3ToVector(farthestRightWall);
    const maxDistance = firstPoint.distanceTo(secondPoint);

    const maxDistanceInMillimeters = (
      Number(convertMetersToMillimeters(maxDistance / multiplyRate)) - 1
    ).toFixed(0);
    //Max distance shouldn't be equal to facade width

    dispatch(
      setDirectionalInputValues([
        {
          ...processingEntity,
          min: 1,
          max: Number(maxDistanceInMillimeters),
          validationMessage: 'Invalid value',
        },
        {
          type:
            processingEntity?.type === DistanceInput.LeftEdgeDistance
              ? DistanceInput.RightEdgeDistance
              : DistanceInput.LeftEdgeDistance,
          min: 1,
          max: Number(maxDistanceInMillimeters),
          validationMessage: 'Invalid value',
        },
      ])
    );
  };
  useEffect(() => {
    currentWall?.guid !== previousActiveWall && findFacadeWalls();
  }, [currentWall, previousActiveWall, mousePosition]);

  return (
    <group>
      {mousePosition && minMax && cutFaceWallPoints && (
        <SplitFaces
          position={mousePosition}
          minMaxCoordinates={minMax}
          wallPoints={cutFaceWallPoints}
          isBuildingClockwise={false}
        />
      )}
    </group>
  );
};

export default CutFace;
