import React, { useEffect, useState } from 'react';
import { Stats } from '@react-three/drei';
import { ProjectStructure } from '@/models';
import ProjectCanvasMap from './ProjectCanvasMap';
import CameraControl from './CameraControls';
import CanvasActionHandlers from '@/shared/components/CanvasActionHandlers/CanvasActionHandlers';
import UserBuilding from '@/routes/dashboard/projects/project/UserBuilding';
import ProjectEnvironment from './Environment/ProjectEnvironment';
import { useThree } from '@react-three/fiber';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import { useParams } from 'react-router';
import { centerCamera } from '@/shared/helpers/camera';
import { useIsolationHandlers } from '@/shared/hooks/useIsolationHandlers';
import { findObjectByUserData } from '@/shared/helpers/canvas';
import { getBuildingsCoordinates } from '@/shared/helpers';
import { flattenDeep } from 'lodash';
import { Box3 } from 'three';
import CanvasInternalElements from './CanvasInternalElements/CanvasInternalElements';

const ProjectCanvas = ({
  map,
  project,
}: {
  map: string;
  project: ProjectStructure;
}) => {
  const { scene, controls, camera } = useThree();
  const { id } = useParams();
  const userBuildings = useFetchProjectQuery(id!).data?.buildings;
  const [controlsIsReady, setControlsIsReady] = useState(false);

  const { isIsolateModeEnabled } = useIsolationHandlers();
  const updateCameraBoundingBox = () => {
    const mapObject = findObjectByUserData(scene, 'map');

    const buildingsOccupiedPoints = flattenDeep(
      getBuildingsCoordinates(userBuildings!)
    );

    const targetedBBox = buildingsOccupiedPoints.length
      ? new Box3().setFromArray(buildingsOccupiedPoints)
      : new Box3().setFromObject(mapObject!);

    if (controlsIsReady) {
      centerCamera({
        boundingBox: targetedBBox,
        camera,
        controls,
      });
    }
  };

  useEffect(() => {
    if (controls !== null && !controlsIsReady) {
      setControlsIsReady(true);
    }
  }, [controls]);

  useEffect(() => {
    updateCameraBoundingBox();
  }, [controlsIsReady]);

  return (
    <>
      <pointLight position={[30, 20, 20]} intensity={1.5} />
      <directionalLight position={[-20, 10, -20]} intensity={9} castShadow />
      <directionalLight position={[20, 10, 20]} intensity={3} castShadow />
      <ProjectCanvasMap map={map} project={project} />
      {!isIsolateModeEnabled && <ProjectEnvironment project={project} />}
      <CanvasInternalElements />
      <UserBuilding />
      <CameraControl />
      <CanvasActionHandlers />
      <Stats className="!absolute !right-[230px] !bottom-6 flex flex-col-reverse !top-auto  !left-auto" />
    </>
  );
};
export default ProjectCanvas;
