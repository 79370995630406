import { Group, Line } from 'react-konva';
import React, { useEffect, useMemo, useState } from 'react';

import { PointerPosition, WindowCreatorModes } from '@/models/shared.model';
import { FlatVector2 } from '@/models';
import { getCenterFrom2DFlatVectorsArray } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { Vector2 } from 'three';
import { Cross } from '@/components/WindowCreator/elements/shared/Cross';
import {
  ACTIVE_ELEMENT_COLOR,
  INITIAL_SCALE,
} from '@/components/WindowCreator/constants';
import { get2DCenter } from '@/shared/helpers/konva';

import { findShapeClosestDistanceToAxis } from '@/components/WindowCreator/helpers/direction.helpers';
import { FlatVector2Axis } from '@/components/WindowCreator/models/konva-model';
import { KonvaEventObject } from 'konva/lib/Node';
import { publish } from '@/core/events';
import { CREATE_MULLION } from '@/core/event-names';
import { round } from 'mathjs';
import { setWindowCreatorMode } from '@/store/slices/windowsReducer/windowCreatorSlice';
import { useAppDispatch } from '@/store/hooks';
import { isLeftClick } from '@/shared/helpers';

const MIN_MULLION_DISTANCE = 130; //60 from frame, 60 from mullion and 10 more. Should be rewritten after BE updates default values

interface MullionGeneratorProps {
  scale: number;
  points: FlatVector2[];
}
export const MullionGenerator = ({ scale, points }: MullionGeneratorProps) => {
  const dispatch = useAppDispatch();
  const [isMullionVertical, setIsMullionVertical] = useState(true);
  const [pointerPosition, setPointerPosition] =
    useState<PointerPosition | null>(null);
  const [mullionAxis, setMullionAxis] = useState<FlatVector2Axis | null>(null);

  const center: Vector2 = useMemo(
    () => getCenterFrom2DFlatVectorsArray(points),
    [points]
  );

  const [isSnapToCenter, setIsSnapToCenter] = useState<boolean>(false);

  useEffect(() => {
    if (pointerPosition) {
      const axisBasedDistanceToCenter = isMullionVertical
        ? Math.abs(center.x - pointerPosition.x)
        : Math.abs(center.y - pointerPosition.y);
      setIsSnapToCenter(
        center.distanceTo(new Vector2(pointerPosition.x, pointerPosition.y)) <
          100 || axisBasedDistanceToCenter < 50
      );

      const distanceToVerticalBorder = findShapeClosestDistanceToAxis(
        points,
        'x',
        pointerPosition.x
      );
      const distanceToHorizontalBorder = findShapeClosestDistanceToAxis(
        points,
        'y',
        pointerPosition.y
      );
      if (distanceToHorizontalBorder < MIN_MULLION_DISTANCE) {
        setIsMullionVertical(true);
      } else if (distanceToVerticalBorder < MIN_MULLION_DISTANCE) {
        setIsMullionVertical(false);
      }
    } else {
      setIsSnapToCenter(false);
    }
  }, [pointerPosition]);

  useEffect(() => {
    if (!pointerPosition) {
      setMullionAxis(null);
      return;
    }
    let axis: FlatVector2Axis | null;
    if (isSnapToCenter) {
      if (isMullionVertical) {
        axis = [
          get2DCenter(points[0], points[1]),
          get2DCenter(points[2], points[3]),
        ];
      } else {
        axis = [
          get2DCenter(points[0], points[3]),
          get2DCenter(points[1], points[2]),
        ];
      }
    } else {
      if (isMullionVertical) {
        axis = [
          [round(pointerPosition.x, 2), points[0][1]],
          [round(pointerPosition.x, 2), points[2][1]],
        ];
      } else {
        axis = [
          [points[0][0], round(pointerPosition.y, 2)],
          [points[2][0], round(pointerPosition.y, 2)],
        ];
      }
    }
    setMullionAxis(axis);
  }, [pointerPosition, isSnapToCenter, isMullionVertical]);

  const handleMouseMove = (event: KonvaEventObject<MouseEvent>) => {
    event.currentTarget?.getRelativePointerPosition() &&
      setPointerPosition(event.currentTarget.getRelativePointerPosition());
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      dispatch(setWindowCreatorMode(WindowCreatorModes.Selection));
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const handleMouseOut = () => {
    setPointerPosition(null);
  };

  const handleClick = (event: KonvaEventObject<MouseEvent>) => {
    if (!isLeftClick(event.evt)) return;
    event.cancelBubble = true;
    setIsSnapToCenter(false);
    setMullionAxis(null);
    publish(CREATE_MULLION, {
      axis: mullionAxis,
      windowPoints: points,
    });
    return;
  };

  const centerSign = () => (
    <Group>
      <Cross
        scale={scale}
        center={[center.x, center.y]}
        lineLength={11 * Math.sqrt(2)}
        degAngle={45}
      />
      <Line
        dash={[15, 15]}
        stroke={ACTIVE_ELEMENT_COLOR}
        strokeWidth={(INITIAL_SCALE * 2) / scale}
        points={
          isMullionVertical
            ? [center.x - 15 / scale, center.y, center.x + 15 / scale, center.y]
            : [center.x, center.y - 15 / scale, center.x, center.y + 15 / scale]
        }
      ></Line>
    </Group>
  );

  return (
    <Group>
      <Line
        onClick={handleClick}
        points={points.flat()}
        closed
        onMouseMove={handleMouseMove}
        onMouseOut={handleMouseOut}
      ></Line>
      {mullionAxis && (
        <Line
          points={mullionAxis?.flat()}
          dash={[15, 15]}
          stroke={ACTIVE_ELEMENT_COLOR}
          strokeWidth={(INITIAL_SCALE * 2) / scale}
        ></Line>
      )}
      {isSnapToCenter && centerSign()}
    </Group>
  );
};
