import React, { useEffect, useState } from 'react';
import { Resizable } from 're-resizable';

import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setMode } from '@/store/slices/canvasModesSlice';
import { CanvasActionsModes, SelectedNode } from '@/models';
import { IntusIconButton } from '@/shared/elements';
import { CrossIcon, CursorIcon } from '@/shared/icons';
import { FacadeDesignerModes } from '@/models/shared.model';
import WindowPlacementIcon from '@/shared/elements/EditToolbar/icons/WindowPlacementIcon';
import { getLeftPanelWidth } from '@/store/slices/sharedSlice';
import FacadeDesignerContainer from '@/components/FacadeDesigner/FacadeDesignerContainer';

import TextTooltip from '@/shared/elements/TextTooltip/TextTooltip';
import escapeIcon from '@/images/EscapeIcon.svg';
import {
  getFacadeDesignerMode,
  setFacadeDesignerMode,
  getSelectedPlacedWindows,
  resetFacadeDesignerSlice,
  setModifiedWalls,
  getModifiedWalls,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { useWindowSize } from 'react-use';
import { resetExternalElementsState } from '@/store/slices/canvasExternalElementsSlice';
import ShortcutTooltip from '@/shared/elements/ShortcutTooltip/ShortcutTooltip';
import shiftIcon from '@/images/shift-icon.svg';
import clickIcon from '@/images/click-icon.svg';
import tabIcon from '@/images/tab-icon.svg';
import CursorClickIcon from '@/shared/icons/CursorClickIcon';
import GridLinePlacementModeIcon from '@/shared/icons/GridLinePlacementModeIcon';
import CleanUpButton from './externalElements/CleanUpButton';
import { isEqual } from 'lodash';

interface FacadeDesignerProps {
  selectedWalls: SelectedNode[];
}

const FacadeDesigner: React.FC<FacadeDesignerProps> = ({ selectedWalls }) => {
  const dispatch = useAppDispatch();
  const facadeDesignerMode = useAppSelector(getFacadeDesignerMode);
  const modifiedWalls = useAppSelector(getModifiedWalls);
  const leftPanelWidth = useAppSelector(getLeftPanelWidth);

  useEffect(() => {
    !selectedWalls.length &&
      dispatch(setFacadeDesignerMode(FacadeDesignerModes.Selection));

    const walls = selectedWalls.map((wall) => wall.guid);
    !isEqual(walls, modifiedWalls) && dispatch(setModifiedWalls(walls));
  }, [selectedWalls]);

  const selectedPlacedWindows = useAppSelector(getSelectedPlacedWindows);
  const { height } = useWindowSize();

  const headerHeight: number =
    document.getElementById('header')?.clientHeight || 0;
  const defaultHeight = height / 2 - headerHeight;

  const [placementHeight, setPlacementHeight] = useState(defaultHeight);

  const placementWidth = window.innerWidth - leftPanelWidth - 220;

  const onKeydown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      if (facadeDesignerMode === FacadeDesignerModes.WindowPlacement) {
        dispatch(setFacadeDesignerMode(FacadeDesignerModes.Selection));
        return;
      } else {
        dispatch(setMode(CanvasActionsModes.selection));
        dispatch(resetFacadeDesignerSlice());
      }
    }
  };

  const getIconFillColor = (expectedMode: FacadeDesignerModes): string => {
    return expectedMode === facadeDesignerMode ? '#fff' : '#414042';
  };

  useEffect(() => {
    return () => {
      dispatch(resetFacadeDesignerSlice());
    };
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', onKeydown);
    return () => {
      document.removeEventListener('keydown', onKeydown);
    };
  }, [facadeDesignerMode, selectedPlacedWindows]);

  const tooltipText = (
    <div className="py-1 px-2 flex items-center bg-white-50">
      <img src={escapeIcon} alt={'tooltip-icon'} className={'pr-1'} />
      <span className="text-xs text-dark-gray-80 leading-5">to exit</span>
    </div>
  );

  const closeFacadeDesigner = () => {
    dispatch(setMode(CanvasActionsModes.selection));
    dispatch(resetExternalElementsState());
    dispatch(resetFacadeDesignerSlice());
  };

  return (
    <>
      <div
        className={`absolute top-0 z-10 border border-solid border-light-gray-20 border-t-0`}
        style={{
          left: leftPanelWidth,
          width: placementWidth,
        }}
      >
        <Resizable
          enable={{
            top: false,
            right: false,
            bottom: true,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
          minHeight={250}
          defaultSize={{
            width: 'auto',
            height: defaultHeight,
          }}
          maxHeight={'75vh'}
          onResize={(_, __, elementRef) =>
            setPlacementHeight(elementRef.offsetHeight)
          }
        >
          <CleanUpButton hasSelectedNodes={!!selectedWalls[0]} />
          <div className="flex justify-center absolute top-2 left-0 right-0 ml-auto mr-auto w-fit z-50">
            <IntusIconButton
              disabled={!selectedWalls[0]}
              type="default"
              className={'mx-1'}
              onClick={() =>
                dispatch(setFacadeDesignerMode(FacadeDesignerModes.Selection))
              }
              icon={
                <CursorIcon
                  fill={getIconFillColor(FacadeDesignerModes.Selection)}
                />
              }
              isActive={FacadeDesignerModes.Selection === facadeDesignerMode}
              id="facade-designer__selectionMode-button"
            />
            <IntusIconButton
              disabled={!selectedWalls[0]}
              type="default"
              className={'mx-1'}
              onClick={() =>
                dispatch(
                  setFacadeDesignerMode(FacadeDesignerModes.WindowPlacement)
                )
              }
              icon={
                <WindowPlacementIcon
                  fill={getIconFillColor(FacadeDesignerModes.WindowPlacement)}
                />
              }
              isActive={
                FacadeDesignerModes.WindowPlacement === facadeDesignerMode
              }
              id="facade-designer__windowPlacementMode-button"
            />
            <IntusIconButton
              disabled={!selectedWalls[0]}
              type="default"
              className={'mx-1'}
              onClick={() =>
                dispatch(
                  setFacadeDesignerMode(FacadeDesignerModes.GridLinePlacement)
                )
              }
              icon={
                <GridLinePlacementModeIcon
                  fill={getIconFillColor(FacadeDesignerModes.GridLinePlacement)}
                />
              }
              isActive={
                FacadeDesignerModes.GridLinePlacement === facadeDesignerMode
              }
              id="facade-designer__gridLinePlacementMode-button"
            />
          </div>
          <div className="absolute top-2 right-6 z-50">
            <IntusIconButton
              className="ml-auto text-white"
              id="facade-designer__close-button"
              icon={<CrossIcon />}
              onClick={closeFacadeDesigner}
            >
              Save
            </IntusIconButton>
          </div>
          <div className={'bg-light-gray-10 w-full h-full min-h-[250px]'}>
            {!!selectedWalls?.length && (
              <FacadeDesignerContainer
                selectedWalls={selectedWalls}
                placementHeight={placementHeight}
                placementWidth={placementWidth}
              />
            )}
          </div>

          <div className="absolute bottom-0 right-0">
            <TextTooltip
              text={tooltipText}
              visible={facadeDesignerMode === FacadeDesignerModes.Selection}
            />
          </div>
        </Resizable>
      </div>
      <ShortcutTooltip
        hints={[
          <div key="wallsSelect" className="flex items-center gap-1 h-5">
            <img src={shiftIcon} alt="Shift icon" />
            <img src={clickIcon} alt="Click icon" />
            <span className="font-normal text-xs leading-5">
              to select multiple walls
            </span>
          </div>,
          <div key="facadeSelect" className="h-5 flex items-center gap-1">
            <img src={tabIcon} alt="Tab icon" />
            <img src={clickIcon} alt="Click icon" />
            <span className="font-normal text-xs leading-5">
              to select the facade
            </span>
          </div>,
        ]}
        footerHints={[
          <div key="selectSeparateWall" className="flex gap-1">
            <CursorClickIcon />
            <span className="font-Roboto text-xs leading-5 font-normal">
              Click to select separate wall
            </span>
          </div>,
        ]}
      />
    </>
  );
};

export default FacadeDesigner;
