import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { IsolatePayload, NodeType, SelectedNode } from '@/models';
import { ExtrudeHandlerData } from '@/routes/dashboard/projects/project/UserBuilding/components/ExtrudeTool/ExtrudeDotHandler';

interface CanvasBuildingState {
  selectedNodes: { [guid: string]: SelectedNode };
  hoveredNodeGUID?: string;
  isolatedNodes: { [guid: string]: boolean };
  extrudeNode?: ExtrudeHandlerData;
  editedNode?: SelectedNode;
}

const initialState: CanvasBuildingState = {
  selectedNodes: {},
  isolatedNodes: {},
};

const defaultSelectedNodes: SelectedNode[] = [];

export const canvasBuildingSlice = createSlice({
  name: 'canvasBuilding',
  initialState,
  reducers: {
    selectOneNode: (state, { payload }: PayloadAction<SelectedNode>) => {
      state.selectedNodes = { ...{ [payload.guid]: payload } };
    },
    addToSelectNode: (state, { payload }: PayloadAction<SelectedNode>) => {
      state.selectedNodes = {
        ...state.selectedNodes,
        ...{ [payload.guid]: payload },
      };
    },
    removeFromSelectNode: (state, { payload }: PayloadAction<string>) => {
      delete state.selectedNodes[payload];
    },
    removeFromSelectedNodeArray: (
      state,
      { payload }: PayloadAction<string[]>
    ) => {
      payload.forEach((key) => delete state.selectedNodes[key]);
    },
    clearSelectedNodes: (state) => {
      state.selectedNodes = initialState.selectedNodes;
    },
    clearSelectedNodesByType: (state, { payload }: PayloadAction<NodeType>) => {
      Object.values(state.selectedNodes).forEach((node) => {
        if (node.type === payload) {
          delete state.selectedNodes[node.guid];
        }
      });
    },
    setHoveredNode: (state, { payload }: PayloadAction<string>) => {
      state.hoveredNodeGUID = payload;
    },

    switchIsolatedFlag: (state, { payload }: PayloadAction<string>) => {
      state.isolatedNodes[payload] = !state.isolatedNodes[payload];
    },
    switchIsolatedFlags: (state, { payload }: PayloadAction<string[]>) => {
      payload.forEach(
        (guid) => (state.isolatedNodes[guid] = !state.isolatedNodes[guid])
      );
    },
    resetHoveredNode: (state) => {
      state.hoveredNodeGUID = initialState.hoveredNodeGUID;
    },
    setIsolatedFlag: (state, { payload }: PayloadAction<IsolatePayload>) => {
      state.isolatedNodes[payload.guid] = payload.isIsolated;
    },
    clearIsolatedNodes: (state) => {
      state.isolatedNodes = initialState.isolatedNodes;
    },
    setExtrudeNode: (
      state,
      { payload }: PayloadAction<ExtrudeHandlerData | undefined>
    ) => {
      state.extrudeNode = payload;
    },
    setEditedNode: (
      state,
      { payload }: PayloadAction<SelectedNode | undefined>
    ) => {
      state.editedNode = payload;
    },
  },
});

export const {
  selectOneNode,
  clearSelectedNodes,
  setHoveredNode,
  resetHoveredNode,
  addToSelectNode,
  removeFromSelectNode,
  removeFromSelectedNodeArray,
  switchIsolatedFlags,
  switchIsolatedFlag,

  clearIsolatedNodes,
  setExtrudeNode,
  setEditedNode,
} = canvasBuildingSlice.actions;

export const getSelectedNodes = (state: RootState) =>
  state.canvasReducer.canvasBuilding.selectedNodes;

export const getHoveredNode = (state: RootState) =>
  state.canvasReducer.canvasBuilding.hoveredNodeGUID;

export const getSelectedNodeByGUID = (guid: string) => (state: RootState) =>
  state.canvasReducer.canvasBuilding.selectedNodes[guid];

export const getIsNodeSelected = (guid: string) => (state: RootState) =>
  !!state.canvasReducer.canvasBuilding.selectedNodes[guid];

export const getIsSingleNodeSelected = (state: RootState): boolean =>
  Object.keys(state.canvasReducer.canvasBuilding.selectedNodes).length === 1;

export const getSelectedNodesByType =
  (type: NodeType) =>
  (state: RootState): SelectedNode[] => {
    const selectedNodes = Object.values(
      state.canvasReducer.canvasBuilding.selectedNodes
    ).filter((node) => type === node.type);
    return selectedNodes.length > 0 ? selectedNodes : defaultSelectedNodes;
  };

export const getLastSelectedNode = (state: RootState) => {
  const selectedNodes = Object.values(
    state.canvasReducer.canvasBuilding.selectedNodes
  );
  return selectedNodes?.length ? selectedNodes[selectedNodes.length - 1] : null;
};

export const getIsNodeHovered = (guid: string) => (state: RootState) =>
  state.canvasReducer.canvasBuilding.hoveredNodeGUID === guid;

export const getIsNodeIsolated =
  (guid: string) =>
  (state: RootState): boolean =>
    state.canvasReducer.canvasBuilding.isolatedNodes[guid];

export const getIsolatedNodes = (state: RootState) =>
  state.canvasReducer.canvasBuilding.isolatedNodes;

export const getExtrudeNode = (state: RootState) =>
  state.canvasReducer.canvasBuilding.extrudeNode;

export const getIsNodeEdited = (guid: string) => (state: RootState) =>
  state.canvasReducer.canvasBuilding.editedNode?.guid === guid;

export const getEditedNode = (state: RootState) =>
  state.canvasReducer.canvasBuilding.editedNode;

export const isSingleNodeIsolated = (state: RootState) =>
  Object.keys(state.canvasReducer.canvasBuilding.isolatedNodes).length === 1;

export const isSurroundingBuildingSelected = (state: RootState) =>
  Object.values(state.canvasReducer.canvasBuilding.selectedNodes).some(
    (node) => node.type === NodeType.SurroundingBuilding
  );
