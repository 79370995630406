import React, { useMemo, useState } from 'react';
import { FlatVector2, WindowPlacementData } from '@/models';
import { WallSearchResults } from '@/shared/hooks/useFindNodeData';
import { FacadeDesignerModes } from '@/models/shared.model';
import {
  addSelectedPlacedWindow,
  getFacadeDesignerMode,
  getHoveredPlacedWindow,
  getSelectedPlacedWindows,
  getSelectedWindowFromLibrary,
  setHoveredPlacedWindow,
  setSelectedPlacedWindow,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  limitValue,
  removeSpacesFromThousands,
} from '@/shared/helpers/format-data';
import { convertMetersToMillimeters } from '@/shared/helpers/distance';
import {
  convert2DPointsToDistanceInMeters,
  getWallHeight,
} from '@/shared/helpers/metrics';
import useFrameProperties from '@/shared/hooks/useFrameProperties';
import { getMultiplyRate } from '@/store/slices/canvasMapSlice';
import { KonvaEventObject } from 'konva/lib/Node';
import { get2DDistance } from '@/shared/helpers/konva';
import { MeasurementUpdateData } from '@/components/WindowCreator/models';
import { round } from 'mathjs';
import { orderBy } from 'lodash';
import { getWindowWidth, isLeftClick } from '@/shared/helpers';
import { Group } from 'react-konva';
import WindowView from '@/components/WindowView/WindowView';
import PlacedWindowStates from '@/components/FacadeDesigner/elements/PlacedWindowStates';
import WallView from '@/components/FacadeDesigner/elements/WallView';
import WindowPlacementMeasurements from '@/components/FacadeDesigner/elements/WindowPlacementMeasurements';
import { useFetchWindowsQuery } from '@/store/apis/windowApi';
import { useParams } from 'react-router';
import { getProjectUnits } from '@/store/slices/projectSlice';
import {
  ExtendedMinMaxCoordinatesPairs,
  getMinMaxCoordinatesAtVector3,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { convertFlatVector3ToVectors } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';

interface FacadeDesignerFloorViewProps {
  startPoint: { x: number; z: number };
  wallData: WallSearchResults;
  scale: number;
  minMaxWallsCoordinates: ExtendedMinMaxCoordinatesPairs;
  onMeasurementUpdate: (data: WindowPlacementData[]) => void;
  onAddWindow: (data: WindowPlacementData[]) => void;
}
const FacadeDesignerWallView = ({
  startPoint,
  wallData,
  scale,
  minMaxWallsCoordinates,
  onMeasurementUpdate,
  onAddWindow,
}: FacadeDesignerFloorViewProps) => {
  const { id } = useParams();
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const windowsData = useFetchWindowsQuery(id!).data!;
  const selectedPlacedWindows = useAppSelector(getSelectedPlacedWindows);
  const facadeDesignerMode = useAppSelector(getFacadeDesignerMode);
  const isSelectionMode = facadeDesignerMode === FacadeDesignerModes.Selection;
  const multiplyRate = useAppSelector(getMultiplyRate);
  const [allowWindowPlacement, setAllowWindowPlacement] =
    useState<boolean>(false);
  const selectedWindowFromLibrary = useAppSelector(
    getSelectedWindowFromLibrary
  );
  const hoveredPlacedWindow = useAppSelector(getHoveredPlacedWindow);
  const isPlacementProcessing = !!selectedWindowFromLibrary;
  const { getWallWidthMetric } = useFrameProperties({
    metricOnly: true,
  });

  const [placedWindowOffset, setPlacedWindowOffset] = useState<number>(-1);

  const dispatch = useAppDispatch();

  const placedWindows: WindowPlacementData[] = useMemo(() => {
    return wallData.windowPlacements || [];
  }, [wallData]);

  const handleMouseEnter = (
    e: KonvaEventObject<MouseEvent>,
    offset: number
  ) => {
    if (!isSelectionMode) return;
    const stage = e.target.getStage();
    stage!.container().style.cursor = 'pointer';
    dispatch(setHoveredPlacedWindow({ wallGUID: wallData.guid, offset }));
  };

  const selectWindow = (e: KonvaEventObject<MouseEvent>, offset: number) => {
    if (!isSelectionMode || !isLeftClick(e.evt)) return;
    if (e.evt.shiftKey) {
      dispatch(addSelectedPlacedWindow({ wallGUID: wallData.guid, offset }));
    } else {
      dispatch(setSelectedPlacedWindow([{ wallGUID: wallData.guid, offset }]));
    }
  };

  const handleMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
    if (!isSelectionMode) return;
    const stage = e.target.getStage();
    stage!.container().style.cursor = 'default';
    dispatch(setHoveredPlacedWindow(null));
  };

  const wallWidth = useMemo(
    () =>
      Number(removeSpacesFromThousands(getWallWidthMetric([wallData]), false)),
    [wallData]
  );

  const wallHeight = useMemo(() => {
    return Number(
      convertMetersToMillimeters(getWallHeight(wallData.points, multiplyRate))
    );
  }, [wallData]);

  const handleClick = () => {
    if (isPlacementProcessing && allowWindowPlacement) {
      const placements = [
        ...placedWindows,
        {
          windowId: selectedWindowFromLibrary.id,
          offsetFromLeftEdge: placedWindowOffset,
        },
      ];
      onAddWindow(placements);
    }
  };

  const calculateWindowPosition = (event?: KonvaEventObject<MouseEvent>) => {
    if (!isPlacementProcessing) return;

    const pointerPosition = event?.currentTarget.getRelativePointerPosition();

    const maxXPosition = limitValue(
      wallWidth -
        get2DDistance(
          selectedWindowFromLibrary.points[0],
          selectedWindowFromLibrary.points[1]
        ),
      0,
      wallWidth
    );

    const offset = limitValue(pointerPosition?.x ?? 0, 0, maxXPosition);
    setPlacedWindowOffset(offset);
  };

  const handleMeasurementSubmit = (data: MeasurementUpdateData) => {
    const distance = data.distance;
    const initialDistance = round(
      data.pointsToUpdate[1][0] - data.pointsToUpdate[0][0],
      2
    );

    const updatedWindows = orderBy(
      placedWindows,
      'offsetFromLeftEdge',
      'asc'
    ).map((pw, idx) => {
      if (pw.offsetFromLeftEdge === data.pointsToUpdate[1][0]) {
        return {
          ...pw,
          offsetFromLeftEdge:
            pw.offsetFromLeftEdge - initialDistance + distance,
        };
      } else if (
        idx === placedWindows.length - 1 &&
        pw.offsetFromLeftEdge < data.pointsToUpdate[0][0]
      ) {
        // most right measurement has been updated
        const savedWindowData = windowsData.find(
          (_w) => _w.id === pw.windowId
        )!;
        return {
          ...pw,
          offsetFromLeftEdge:
            wallWidth - getWindowWidth(savedWindowData) - data.distance,
        };
      }
      return pw;
    });

    onMeasurementUpdate(updatedWindows);
  };

  const showAddedWindows = () =>
    placedWindows.map((window, idx) => {
      const windowToDisplay = windowsData.find(
        (_w) => _w.id === window.windowId
      );
      if (!windowToDisplay) return null;
      return (
        <Group
          key={`window-placement__${window.windowId}__${idx}`}
          onMouseEnter={(e) => handleMouseEnter(e, window.offsetFromLeftEdge)}
          onMouseLeave={handleMouseLeave}
          onClick={(e) => selectWindow(e, window.offsetFromLeftEdge)}
        >
          <WindowView
            data={windowToDisplay}
            scale={scale}
            offsetX={window.offsetFromLeftEdge}
            offsetY={wallHeight - windowToDisplay.distanceToFloor}
            units={unitSystem}
          />
          {!isPlacementProcessing && (
            <PlacedWindowStates
              data={windowToDisplay}
              isHovered={
                window.offsetFromLeftEdge === hoveredPlacedWindow?.offset &&
                hoveredPlacedWindow?.wallGUID === wallData.guid
              }
              isSelected={selectedPlacedWindows.some(
                (spw) =>
                  spw.wallGUID === wallData.guid &&
                  spw.offset === window.offsetFromLeftEdge
              )}
              offsetX={window.offsetFromLeftEdge}
              offsetY={wallHeight - windowToDisplay.distanceToFloor}
            />
          )}
        </Group>
      );
    });

  const resetSelectedWindow = (e: KonvaEventObject<MouseEvent>) => {
    if (!isSelectionMode || !isLeftClick(e.evt)) return;
    e.cancelBubble = true;
    dispatch(setSelectedPlacedWindow([]));
  };

  const wallCoordinates = getMinMaxCoordinatesAtVector3(
    convertFlatVector3ToVectors(wallData.points)
  );

  const wallBottomLeftPoint: FlatVector2 = [
    wallData.points[1][0],
    wallData.points[1][2],
  ];

  const wallOffset = {
    x: round(
      Number(
        convertMetersToMillimeters(
          convert2DPointsToDistanceInMeters(
            wallBottomLeftPoint,
            [startPoint.x, startPoint.z],
            multiplyRate
          )
        )
      ),
      2
    ),
    y: round(
      Number(
        convertMetersToMillimeters(
          (wallCoordinates.max.y - minMaxWallsCoordinates.max.y) / multiplyRate
        )
      ),
      2
    ),
  };

  return (
    <Group
      offsetX={-wallOffset.x}
      offsetY={wallOffset.y}
      onClick={handleClick}
      onMouseEnter={() => setAllowWindowPlacement(true)}
      onMouseMove={calculateWindowPosition}
      onMouseLeave={() => setAllowWindowPlacement(false)}
    >
      <Group onClick={resetSelectedWindow}>
        <WallView
          wallWidth={wallWidth}
          wallHeight={wallHeight}
          scale={scale}
          initialScale={scale}
        />
      </Group>

      {selectedWindowFromLibrary && allowWindowPlacement && (
        <Group opacity={0.75} listening={false}>
          <WindowView
            data={selectedWindowFromLibrary}
            scale={scale}
            units={unitSystem}
            offsetX={placedWindowOffset}
            offsetY={wallHeight - selectedWindowFromLibrary.distanceToFloor}
            viewOnly
          />
        </Group>
      )}
      {placedWindows?.length > 0 && (
        <WindowPlacementMeasurements
          placedWindows={placedWindows}
          windowsData={windowsData}
          units={unitSystem}
          scale={scale}
          wallHeight={wallHeight}
          wallWidth={wallWidth}
          onMeasurementSubmit={handleMeasurementSubmit}
        />
      )}
      {showAddedWindows()}
    </Group>
  );
};

export default FacadeDesignerWallView;
